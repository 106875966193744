@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/poppins-v20-latin-ext_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/poppins-v20-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v20-latin-ext_latin-regular.woff2") format("woff2"), url("../fonts/poppins-v20-latin-ext_latin-regular.woff") format("woff"), url("../fonts/poppins-v20-latin-ext_latin-regular.ttf") format("truetype"), url("../fonts/poppins-v20-latin-ext_latin-regular.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* poppins-600 - latin-ext_latin */
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/poppins-v20-latin-ext_latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/poppins-v20-latin-ext_latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v20-latin-ext_latin-600.woff2") format("woff2"), url("../fonts/poppins-v20-latin-ext_latin-600.woff") format("woff"), url("../fonts/poppins-v20-latin-ext_latin-600.ttf") format("truetype"), url("../fonts/poppins-v20-latin-ext_latin-600.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* poppins-700 - latin-ext_latin */
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/poppins-v20-latin-ext_latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/poppins-v20-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v20-latin-ext_latin-700.woff2") format("woff2"), url("../fonts/poppins-v20-latin-ext_latin-700.woff") format("woff"), url("../fonts/poppins-v20-latin-ext_latin-700.ttf") format("truetype"), url("../fonts/poppins-v20-latin-ext_latin-700.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* poppins-800 - latin-ext_latin */
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/poppins-v20-latin-ext_latin-800.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/poppins-v20-latin-ext_latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v20-latin-ext_latin-800.woff2") format("woff2"), url("../fonts/poppins-v20-latin-ext_latin-800.woff") format("woff"), url("../fonts/poppins-v20-latin-ext_latin-800.ttf") format("truetype"), url("../fonts/poppins-v20-latin-ext_latin-800.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* poppins-900 - latin-ext_latin */
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/poppins-v20-latin-ext_latin-900.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/poppins-v20-latin-ext_latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v20-latin-ext_latin-900.woff2") format("woff2"), url("../fonts/poppins-v20-latin-ext_latin-900.woff") format("woff"), url("../fonts/poppins-v20-latin-ext_latin-900.ttf") format("truetype"), url("../fonts/poppins-v20-latin-ext_latin-900.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-300 - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/source-sans-pro-v21-latin-ext_latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-300.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-ext_latin-300.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-ext_latin-300.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-300.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-regular - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v21-latin-ext_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-ext_latin-regular.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-ext_latin-regular.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-regular.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-600 - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/source-sans-pro-v21-latin-ext_latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-ext_latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-600.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-ext_latin-600.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-ext_latin-600.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-600.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-700 - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v21-latin-ext_latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-700.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-ext_latin-700.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-ext_latin-700.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-700.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-900 - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/source-sans-pro-v21-latin-ext_latin-900.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/source-sans-pro-v21-latin-ext_latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-900.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-ext_latin-900.woff") format("woff"), url("../fonts/source-sans-pro-v21-latin-ext_latin-900.ttf") format("truetype"), url("../fonts/source-sans-pro-v21-latin-ext_latin-900.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

em {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

html {
  scroll-behavior: smooth;
}

body {
  line-height: 1.6;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  color: #333;
  font-style: normal;
  scroll-behavior: smooth;
}

iframe,
video {
  max-width: 100%;
}

.film__wrapper {
  display: block;
  width: 100%;
  padding-top: 56.25%;
  height: 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.film__wrapper iframe,
.film__wrapper video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.info {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid #00AF9A;
  padding: 15px 20px;
  display: block;
  font-size: 18px;
}
@media (min-width: 576px) {
  .info {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 40px;
  }
}

.info--false {
  color: #D00035;
  border-color: #D00035;
}

.info--true {
  color: #00AF9A;
  border-color: #00AF9A;
}

blockquote {
  color: #00AF9A;
}

blockquote,
.title--decor {
  font-weight: 900;
  font-size: 24px;
  padding-left: 25px;
  position: relative;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
}
blockquote:after,
.title--decor:after {
  content: "";
  background: #00AF9A;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 9px;
  background: #00AF9A;
}
@media (max-width: 576px) {
  blockquote,
.title--decor {
    font-size: 21px;
  }
}

.title--decor {
  text-transform: uppercase;
  margin-bottom: 30px !important;
}

.main {
  color: #00AF9A !important;
}

strong {
  font-weight: 900;
}

.font-weight-bold {
  font-weight: 900 !important;
}

.bold {
  font-weight: 900 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font--12 {
  font-size: 12px !important;
}

.font--18 {
  font-size: 18px !important;
}

.font--20 {
  font-size: 20px !important;
}

.font--24 {
  font-size: 24px !important;
}
@media (max-width: 576px) {
  .font--24 {
    font-size: 21px !important;
  }
}

.font--30 {
  font-size: 30px !important;
  margin-bottom: 30px !important;
}
@media (max-width: 576px) {
  .font--30 {
    font-size: 21px !important;
    margin-bottom: 20px !important;
  }
}

.txt-center {
  text-align: center !important;
}

input:not([type=date], [type=number]),
textarea,
button {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: "Source Sans Pro", sans-serif;
}

button {
  padding: 0;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid #00AF9A;
  outline-offset: 2px;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a {
  color: #333;
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
  color: rgba(51, 51, 51, 0.8);
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

h1,
h2,
h3 {
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
}

h1,
.h1 {
  font-size: 50px;
  margin-bottom: 30px;
}

h2 {
  font-size: 50px;
  margin-bottom: 40px;
}

h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

h4,
h5,
h6 {
  font-size: 18px;
  font-weight: 900;
}

@media (max-width: 992px) {
  h1,
.h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  h1,
.h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 34px;
    margin-bottom: 25px;
  }

  h3,
h4,
h5,
h6 {
    font-size: 16px;
  }
}
p,
ul,
ol {
  line-height: 1.6;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

.page__txt h2 {
  font-size: 24px;
  margin-top: 50px;
  position: relative;
  padding-left: 25px;
}
.page__txt h2:after {
  content: "";
  background: #00AF9A;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 9px;
  background: #00AF9A;
}
.page__txt blockquote {
  margin-top: 40px;
}
.page__txt img {
  max-width: 100%;
  height: auto;
  margin-top: 15px;
  margin-bottom: 20px;
}
.page__txt ul,
.page__txt ol {
  list-style: initial;
  padding-left: 30px;
}
.page__txt ol {
  list-style: decimal;
}
.page__txt ol,
.page__txt ul,
.page__txt p {
  margin-bottom: 20px;
  font-size: 18px;
}
.page__txt li {
  margin-bottom: 5px;
}
.page__txt a {
  -webkit-text-decoration: underline 2px;
          text-decoration: underline 2px;
}
@media (hover: hover) {
  .page__txt a:hover {
    color: #00AF9A;
  }
}
@media (max-width: 576px) {
  .page__txt h2,
.page__txt blockquote {
    font-size: 21px;
  }
  .page__txt ol,
.page__txt ul,
.page__txt p {
    margin-bottom: 15px;
    font-size: 16px;
  }
}

a,
span,
strong {
  line-height: inherit;
}

.link--mainBold {
  font-weight: 900;
  color: #00AF9A;
}
.link--mainBold:hover {
  color: #D00035;
}

.link--main {
  text-decoration: underline;
  color: #00AF9A !important;
}
.link--main:hover {
  color: #D00035 !important;
}

.link--underline {
  text-decoration: underline;
  text-decoration-thickness: 4px;
  color: #333;
  font-weight: 900;
  font-size: 18px;
}
.link--underline:hover {
  color: #00AF9A;
}

.link--underlineW {
  color: #fff;
}
.link--underlineW:hover {
  color: #00AF9A;
}

.link--dark {
  color: #333;
}
@media (hover: hover) {
  .link--dark:hover {
    color: #00AF9A;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.section {
  padding-top: 50px;
  padding-bottom: 40px;
}
@media (min-width: 992px) {
  .section {
    padding-top: 90px;
    padding-bottom: 70px;
  }
}

.section--article {
  margin-top: -80px;
  padding-top: 120px;
  padding-bottom: 60px;
}
@media (max-width: 992px) {
  .section--article {
    padding-top: 110px;
    padding-bottom: 40px;
  }
}

.section--50 {
  padding-top: 50px;
  padding-bottom: 40px;
}

.section--20 {
  padding-top: 20px;
  padding-bottom: 30px;
}

.section--30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.fluid__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.fluid--50 {
  max-width: 100%;
  flex: 0 0 100%;
}
@media (min-width: 992px) {
  .fluid--50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.w-100 {
  width: 100% !important;
}

.container {
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
}
@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 930px;
  }
  .container.container--sm {
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .container.container--md {
    max-width: 1140px;
  }
  .container .container--md {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1480px) {
  .container {
    max-width: 1400px;
  }
}

@media (max-width: 576px) {
  .container--mobile-0 {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
  }
  .container--mobile-0 h2 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .container--mobile-0 > p {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container--half {
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
}
@media (min-width: 768px) {
  .container--half {
    max-width: 700px;
  }
}
@media (min-width: 992px) {
  .container--half {
    max-width: 465px;
  }
}
@media (min-width: 1200px) {
  .container--half {
    max-width: 570px;
  }
}
@media (min-width: 1480px) {
  .container--half {
    max-width: 700px;
  }
}

@media (max-width: 992px) {
  .container--half-lg {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .container--half-lg img {
    width: 100%;
    height: auto;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-right: -30px;
}
@media (max-width: 576px) {
  .row {
    margin-left: -7px;
    margin-right: -7px;
  }
}
.row.row--0 {
  margin-left: 0px;
  margin-right: 0px;
}

[class*=col-] {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
  width: 100%;
}
@media (max-width: 576px) {
  [class*=col-] {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 14px;
  }
}

.row--20 {
  margin-left: -10px;
  margin-right: -10px;
}
.row--20 [class*=col-] {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .row--20 [class*=col-] {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 14px;
  }
}
@media (max-width: 576px) {
  .row--20 {
    margin-left: -7px;
    margin-right: -7px;
  }
}

@media (min-width: 768px) {
  .col-md-50,
.col-md-6 {
    width: 50%;
    flex: 0 0 50%;
  }

  .col-md-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-md-5 {
    max-width: 41.67%;
    flex: 0 0 41.67%;
  }

  .col-md-7 {
    max-width: 58.33%;
    flex: 0 0 58.33%;
  }

  .col-md-8 {
    max-width: 66.67%;
    flex: 0 0 66.67%;
  }
}
@media (min-width: 992px) {
  .col-lg-50 {
    width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 50%;
  }
}
.header__fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.bg--grey {
  background: #F4F5F0;
}

.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

@media (min-width: 992px) {
  .ml-0-lg {
    margin-left: 0 !important;
  }

  .mr-0-lg {
    margin-right: 0 !important;
  }

  .pl-0-lg {
    padding-left: 0px;
  }

  .pl-60-lg {
    padding-left: 60px;
  }

  .pr-60-lg {
    padding-right: 60px;
  }

  .pt-60-lg {
    padding-top: 60px;
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 768px) {
  .mt-100 {
    margin-top: 50px !important;
  }
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.btn {
  background: #00AF9A;
  color: #fff;
  text-transform: uppercase;
  padding: 15px 30px;
  font-size: 12px;
  white-space: nowrap;
  line-height: 1;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
@media (hover: hover) {
  .btn:hover {
    background: #D00035;
  }
}

.btn--white {
  background: #fff;
  color: #00AF9A;
  border-color: #00AF9A;
}
@media (hover: hover) {
  .btn--white:hover {
    background: #00AF9A;
    color: #fff;
  }
}

.btn--arrow {
  padding-right: 50px;
  position: relative;
}
.btn--arrow:after {
  content: "";
  position: absolute;
  right: 20px;
  top: 13px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff;
}

.btn2 {
  background: transparent;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #333;
  position: relative;
  padding: 10px 0;
}
.btn2:after {
  content: "";
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  bottom: 5px;
  left: 0;
  background: #333;
  transition: all 0.3s ease-in-out;
}
@media (hover: hover) {
  .btn2:hover:after {
    width: 20%;
    background: #D00035;
  }
}

.btn2--black {
  color: #000;
}
.btn2--black:after {
  background: #000;
}
@media (hover: hover) {
  .btn2--black:hover:after {
    background: #D00035;
  }
}

.btn2--white {
  color: #fff;
  line-height: 1.4;
}
.btn2--white:after {
  background: #fff;
  height: 2px;
}
@media (hover: hover) {
  .btn2--white:hover:after {
    background: #00AF9A;
  }
}

.btn3, .page__header .tagSmall__list a {
  background: transparent;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  position: relative;
  padding: 10px 0px 10px 0;
  display: inline-block;
}
.btn3:after, .page__header .tagSmall__list a:after {
  content: "";
  width: 0;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 3;
  left: 0;
  background: #333;
  transition: all 0.3s ease-in-out;
}
.btn3.active:after, .page__header .tagSmall__list a.active:after {
  width: 100%;
}
@media (hover: hover) {
  .btn3:hover, .page__header .tagSmall__list a:hover {
    color: #333;
  }
  .btn3:hover:after, .page__header .tagSmall__list a:hover:after {
    width: 100%;
  }
}

.page__header .tagSmall__list li {
  padding-right: 15px;
  padding-left: 15px;
}
.page__header .tagSmall__list li:before {
  width: 4px;
  height: 4px;
  top: 50%;
  margin-top: -2px;
  left: -2px;
}
.btn4 {
  font-size: 18px;
  text-align: left;
  font-weight: 900;
}
.btn4 span,
.btn4 a {
  background-image: linear-gradient(#000, #000);
  padding-bottom: 5px;
  background-position: 0px 93%;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: all 0.5s ease-out;
  line-height: 1.8;
}
@media (hover: hover) {
  .btn4:hover span,
.btn4:hover a {
    background-size: 15% 2px;
    background-image: linear-gradient(#00AF9A, #00AF9A);
  }
}
@media (max-width: 576px) {
  .btn4 {
    font-size: 16px;
  }
  .btn4 span {
    background-position: 0px 83%;
  }
}

.btn5 span,
.btn5 a {
  background-image: linear-gradient(#00AF9A, #00AF9A);
  padding-bottom: 5px;
  background-position: 0px 90%;
  background-repeat: no-repeat;
  background-size: 0 4px;
  transition: all 0.3s ease-out;
}
@media (hover: hover) {
  .btn5:hover span,
.btn5:hover a {
    background-size: 100% 4px;
  }
}
@media (max-width: 576px) {
  .btn5 {
    font-size: 16px;
  }
  .btn5 span {
    background-position: 0px 83%;
  }
}

.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;
}
.img__wrapper img,
.img__wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img__wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.pagination {
  border-top: 1px solid #ddd;
  padding-top: 60px;
  padding-bottom: 60px;
}
.pagination ul {
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
}
.pagination ul a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid #00AF9A;
  font-weight: 900;
  color: #00AF9A;
  font-size: 18px;
}
.pagination ul a.p-more {
  border: none;
}
.pagination ul a svg {
  stroke: #000;
  transition: all 0.3s ease-in-out;
}
@media (hover: hover) {
  .pagination ul a:hover {
    border-color: #000;
    color: #000;
  }
  .pagination ul a:hover svg {
    stroke: #00AF9A;
  }
}
.pagination ul .active a {
  color: #000;
  border: none;
}
.pagination ul .prev,
.pagination ul .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.pagination ul .prev a,
.pagination ul .next a {
  border: none;
}
.pagination ul .prev.disabled,
.pagination ul .next.disabled {
  opacity: 0;
}
.pagination ul .prev {
  left: 0;
}
.pagination ul .next {
  right: 0;
}
@media (max-width: 992px) {
  .pagination ul {
    gap: 5px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .pagination ul a {
    width: 34px;
    height: 34px;
  }
}

.gallery__list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-left: -30px;
  margin-right: -30px;
  list-style: none !important;
  padding-left: 0 !important;
}
.gallery__list .gallery__link {
  padding-top: 60%;
}
.gallery__list li {
  width: 100%;
  max-width: calc(50% - 2px);
  flex: 0 0 50%;
  margin-bottom: 0 !important;
}
@media (min-width: 576px) {
  .gallery__list {
    margin-left: 0px;
    margin-right: 0px;
  }
  .gallery__list li {
    width: 100%;
    max-width: calc(25% - 3px);
    flex: 0 0 25%;
  }
}
@media (min-width: 1200px) {
  .gallery__list {
    gap: 16px;
  }
  .gallery__list li {
    width: 100%;
    max-width: calc(25% - 12px);
  }
}

.gallery__list--1 {
  display: flex;
  flex-wrap: wrap;
  list-style: none !important;
  padding-left: 0 !important;
  margin-left: 0px;
  margin-right: 0px;
  gap: 0;
}
.gallery__list--1 li {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
  margin-bottom: 0 !important;
}
@media (min-width: 576px) {
  .gallery__list--1 li {
    width: 100%;
    max-width: 25%;
    flex: 0 0 25%;
    border-bottom: 1px solid #F4F5F0;
    border-right: 1px solid #F4F5F0;
  }
  .gallery__list--1 li:nth-child(3n+1) {
    border-right: 0;
  }
  .gallery__list--1 li:first-child {
    border-right: 1px solid #F4F5F0;
  }
}

.gallery__link {
  display: block;
  padding-top: 56%;
}
.gallery__link:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgba(0, 175, 154, 0.5);
  z-index: 10;
  transition: all 0.3s ease-out;
}
.gallery__link:before {
  content: url("../img/btn-search-white.svg");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-out 0s;
}
@media (hover: hover) {
  .gallery__link:hover:after {
    width: 100%;
  }
  .gallery__link:hover:before {
    z-index: 12;
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
}

.gallery__embed {
  width: 100%;
  padding-top: 60%;
  position: relative;
  overflow: hidden;
  height: 0;
}
.gallery__embed iframe {
  position: absolute;
  top: 0;
  left: 0;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  padding: 30px;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__close {
  position: absolute;
  top: 30px;
  right: 30px;
  border: none;
  outline: none;
  background: none;
  width: 50px;
  height: 50px;
  display: inline-block;
  color: #333;
}
.modal__close:after {
  content: "+";
  transform: rotate(45deg);
  font-size: 54px;
  position: absolute;
  top: -8px;
  left: 10px;
  transition: all 0.3s ease-out;
}
.modal__close:hover {
  color: #D00035;
}
.modal__container {
  position: relative;
  z-index: 22;
  width: 700px;
  max-width: 96%;
  max-height: 80%;
  overflow: auto;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
}
.modal__title {
  color: #333;
  font-size: 24px;
  padding-right: 20px;
  margin-bottom: 30px;
  text-align: left;
}
.modal .form__input input {
  border: 1px solid #ddd;
}
.modal .form__checkbox label:before {
  border-color: #ddd;
}
@media (max-width: 576px) {
  .modal__container {
    padding: 20px;
  }
  .modal__close {
    top: 10px;
    right: 10px;
  }
}

input,
textarea,
select {
  border: 1px solid #707070;
  line-height: 1.6;
  padding: 10px 15px;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
}

::-moz-placeholder {
  color: #aaa;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #aaa;
  font-weight: 300;
}

::placeholder {
  color: #aaa;
  font-weight: 300;
}

.disabled {
  color: #aaa;
}

@-webkit-keyframes selectAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes selectAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.form__select {
  border: 1px solid #707070;
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 25px;
  z-index: 10;
  min-width: 150px;
}
.form__select label {
  color: #aaa;
  font-size: 16px;
  position: absolute;
  left: 20px;
  bottom: 13px;
  padding: 0;
  font-weight: 300;
  z-index: -1;
}
.form__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  padding: 13px 80px 13px 20px;
  border: none;
  display: block;
  background: transparent;
  width: 100%;
  padding-right: 40px;
  color: transparent;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  cursor: pointer;
}
.form__select select:focus {
  outline-offset: 0px;
}
.form__select select.js-selected {
  color: #000;
}
.form__select select.js-selected + label {
  bottom: 105%;
  left: 15px;
  font-size: 10px !important;
  -webkit-animation: selectAnimation 0.3s ease-out;
          animation: selectAnimation 0.3s ease-out;
}
.form__select option {
  border: none;
  padding: 10px;
  font-size: 16px;
  color: #000;
}
.form__select:after {
  content: url("../img/arrow-select.svg");
  position: absolute;
  right: 5px;
  top: 15px;
  z-index: -1;
  background: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
}
@media (max-width: 576px) {
  .form__select {
    width: 100%;
  }
  .form__select select {
    width: 100%;
  }
}
.form__select.form__select--white {
  background: #fff;
  border: none;
  width: 100%;
  margin-top: 0;
  margin-bottom: 25px;
}
.form__select.form__select--white label {
  font-size: 18px;
}
.form__select.form__select--white select {
  font-size: 18px;
}
.form__select.form__select--white option {
  font-size: 18px;
}
.form__select.is--invalid:after {
  content: url("../img/arrow-select-red.svg");
}

.form__checkbox {
  margin-bottom: 15px;
}
.form__checkbox input {
  position: absolute;
  left: -5000px;
  opacity: 0;
}
.form__checkbox a {
  text-decoration: underline;
}
.form__checkbox label {
  position: relative;
  padding-left: 40px;
  line-height: 20px;
  color: #333;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
}
.form__checkbox label:before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 1px;
  left: 0;
  display: block;
  border: 1px solid #fff;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 8px;
  width: 0;
  height: 0px;
  transform: rotate(-45deg);
}
.form__checkbox input:checked ~ label:before {
  background-color: #00AF9A;
}
.form__checkbox input:checked ~ label:after {
  width: 15px;
  height: 11px;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
}
.form__checkbox input:focus-visible ~ label:before {
  outline: 2px solid #00af9a;
  outline-offset: 2px;
}
.form__checkbox input[type=radio]:before {
  border-radius: 50%;
}
.form__checkbox input[type=radio]:checked ~ label:after {
  width: 10px;
  height: 10px;
  background: #00AF9A;
}
.form__checkbox.is--invalid label:before {
  border-color: #D00035;
}

.form__checkbox--big label {
  font-size: 16px;
  line-height: 30px;
}

.form__input {
  margin-bottom: 25px;
  position: relative;
}
.form__input input,
.form__input textarea {
  width: 100%;
  border: none;
  padding: 12px 15px;
  color: #333;
  font-size: 18px;
  border: none;
  font-weight: 300;
  line-height: 1.5;
}
.form__input label {
  top: 15px;
  font-size: 16px;
  z-index: 2;
  padding: 3px 30px 3px 0;
  width: auto;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  left: 0;
  line-height: 16px;
  border-radius: 10px;
  position: absolute;
  transition: top 0.3s ease-in-out;
  color: #aaa;
  white-space: nowrap;
  opacity: 0;
  z-index: -1;
}
.form__input input:focus + label,
.form__input .focus,
.form__input textarea:focus + label, .form__input.noempty label {
  top: -18px;
  font-size: 10px !important;
  z-index: 2;
  padding: 3px 30px 3px 0;
  width: auto;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  left: 15px;
  line-height: 10px;
  border-radius: 10px;
  opacity: 1;
  z-index: initial;
  color: rgba(51, 51, 51, 0.5);
}
.form__input input:focus::-moz-placeholder {
  color: transparent;
}
.form__input input:focus:-ms-input-placeholder {
  color: transparent;
}
.form__input input:focus::placeholder {
  color: transparent;
}
.form__input.is--invalid {
  color: #D00035;
}
.form__input.is--invalid:after {
  position: absolute;
  right: 15px;
  top: 2px;
  content: "+";
  font-size: 30px;
  transform: rotate(45deg);
}
.form__input--error {
  color: #D00035;
  font-size: 10px;
  display: block;
  margin-top: 5px;
}

.acc__content {
  display: none;
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}

.fieldset {
  border: 1px solid #000;
  padding: 30px 30px 5px 30px;
  margin-bottom: 30px;
}
.fieldset legend {
  font-weight: 700;
  font-size: 20px;
}

.fieldset__row {
  gap: 0 30px;
  display: flex;
  flex-wrap: wrap;
}
.fieldset__row .form__input {
  width: 100%;
}
@media (min-width: 768px) {
  .fieldset__row .form__input {
    flex: 0 0 50%;
    max-width: calc(50% - 15px);
  }
}

.form__file {
  position: relative;
}
.form__file input {
  border: none;
  background: #fff;
  height: 50px;
  margin-bottom: 25px;
}
.form__file label {
  position: absolute;
  top: -25px;
  left: 0;
}

.fieldset__list li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 768px) {
  .fieldset__list .form__input {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@-webkit-keyframes goTop {
  0% {
    top: 30px;
  }
  25% {
    top: 25px;
  }
  50% {
    top: 30px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 30px;
  }
}

@keyframes goTop {
  0% {
    top: 30px;
  }
  25% {
    top: 25px;
  }
  50% {
    top: 30px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 30px;
  }
}
.go-top {
  position: absolute;
  top: 30px;
  right: 80px;
  z-index: 98;
  padding-right: 80px;
  font-weight: 700;
  font-size: 9px;
  text-transform: uppercase;
  transform: rotate(-90deg);
  color: #fff;
  background: transparent;
  border: none;
}
.go-top:before {
  content: "";
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: block;
  background: #FCFCFA;
  position: absolute;
  top: -30px;
  right: 0;
  transition: background 0.3s ease-in-out;
}
.go-top:after {
  content: url("../img/arrow-top.svg");
  position: absolute;
  top: -14px;
  right: 30px;
  transform: rotate(90deg);
}
@media (hover: hover) {
  .go-top:hover {
    color: #fff;
    -webkit-animation: goTop 1s ease-in-out infinite;
            animation: goTop 1s ease-in-out infinite;
    transition: top 0.3s ease-in-out;
  }
  .go-top:hover:before {
    background: rgba(0, 175, 154, 0.83);
  }
}
@media (max-width: 1200px) {
  .go-top {
    right: 0px;
  }
}

.go-bottom {
  position: absolute;
  bottom: 10px;
  left: 0px;
  z-index: 100;
  padding-left: 30px;
  font-weight: 700;
  font-size: 9px;
  text-transform: uppercase;
  transform: rotate(-90deg);
  color: #00AF9A;
  z-index: 30;
}
.go-bottom:after {
  content: url("../img/arrow-down-s-m.svg");
  position: absolute;
  top: -4px;
  left: 0px;
  transform: rotate(90deg);
}
@media (hover: hover) {
  .go-bottom:hover {
    color: #00AF9A;
  }
}
@media (max-width: 992px) {
  .go-bottom {
    bottom: 40px;
    padding-left: 20px;
    left: -20px;
  }
}

.footer {
  background: #333333;
  position: relative;
  color: #fff;
}

.footer__top {
  padding-top: 70px;
  padding-bottom: 30px;
}
.footer__top ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.footer__top ul > li {
  position: relative;
}
.footer__top ul > li a {
  padding: 5px;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}
@media (hover: hover) {
  .footer__top ul > li a:hover {
    color: #00AF9A;
  }
}
@media (max-width: 1200px) {
  .footer__top {
    padding-top: 30px;
  }
  .footer__top ul {
    row-gap: 0px;
    -moz-column-gap: 15px;
         column-gap: 15px;
  }
  .footer__top ul > li:not(:last-child):after {
    right: -9px;
  }
}
@media (max-width: 576px) {
  .footer__top ul {
    flex-direction: column;
    justify-content: left;
    padding-left: 30px;
  }
  .footer__top ul li:after {
    left: -25px;
    right: initial;
  }
  .footer__top ul li:last-child:after {
    content: "";
  }
}

.footer__center {
  display: grid;
  gap: 30px;
  padding-top: 20px;
  padding-bottom: 35px;
}
.footer__center svg {
  fill: #fff !important;
}
@media (min-width: 992px) {
  .footer__center {
    grid-template-columns: 250px 340px 1fr;
    gap: 20px;
  }
}
@media (min-width: 1200px) {
  .footer__center {
    gap: 60px;
    grid-template-columns: 340px 400px 1fr;
  }
}
@media (max-width: 992px) {
  .footer__center {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .footer__center .footer__social img {
    max-width: 30px;
  }
}

.footer__contact {
  font-size: 14px;
}
.footer__contact span:after {
  content: "";
  width: 88px;
  height: 1px;
  background: #F4F5F0;
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  position: relative;
  bottom: 4px;
}
.footer__contact a {
  color: #00AF9A;
  font-weight: 700;
}
@media (hover: hover) {
  .footer__contact a:hover {
    color: #D00035;
  }
}
.footer__contact p:nth-child(2) span:after {
  width: 63px;
}
@media (max-width: 576px) {
  .footer__contact p:nth-child(2) span:after {
    width: 13px;
  }
}
@media (max-width: 576px) {
  .footer__contact p span:after {
    width: 10px;
  }
}

.footer__bottom {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 16px;
  background: #000;
}
.footer__bottom ul {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  gap: 40px;
  align-items: center;
  padding-right: 0;
}
.footer__bottom li {
  text-align: center;
}
.footer__bottom img {
  max-height: 40px;
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 992px) {
  .footer__bottom ul {
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .footer__bottom ul {
    flex-wrap: wrap;
  }
}
@media (max-width: 576px) {
  .footer__bottom ul li {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }
  .footer__bottom ul .sm-100 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.footer__social {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
}
.footer__social svg {
  fill: #fff !important;
}
@media (hover: hover) {
  .footer__social a:hover svg {
    fill: #00AF9A !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .footer__social {
    flex-wrap: wrap;
  }
}

.footer__brand {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #9A9A9A;
  padding-bottom: 30px;
  padding-top: 20px;
}
.footer__brand a {
  display: inline-block;
  padding: 5px;
}
@media (max-width: 992px) {
  .footer__brand {
    justify-content: center;
  }
}

.menu-open .header {
  background: #00AF9A;
}

@-webkit-keyframes fixedMenuDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

@keyframes fixedMenuDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
.fixed-header-scroll {
  background: #000 !important;
  position: fixed;
  top: 0;
  -webkit-animation-name: fixedMenuDown;
          animation-name: fixedMenuDown;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}
.fixed-header-scroll .header__lang {
  display: none;
}
.fixed-header-scroll .header__logo {
  transform: scale(0.7);
  color: #fff !important;
  fill: #fff !important;
}
.fixed-header-scroll .header__wrapper {
  height: 60px !important;
  padding-top: 0 !important;
}
.fixed-header-scroll .nav {
  top: 60px;
}
.fixed-header-scroll .link__recrutation {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 1480px) {
  .fixed-header-scroll .nav > ul .nav__level-2 {
    padding-top: 60px;
  }
}

.header__wrapper {
  display: flex;
  position: relative;
  z-index: 100;
  height: 110px;
  padding-top: 40px;
  align-items: center;
  justify-content: space-between;
}

.subpage {
  padding-top: 110px;
}
.subpage .header__wrapper {
  padding-top: 10px;
}
.subpage .header {
  background: #000;
}
.subpage .header__lang {
  top: 42px;
}

.header__logo {
  color: #fff;
  fill: #00AF9A;
  margin-right: 100px;
  transition: none;
}
.header__logo svg {
  transition: none;
}

.header__social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-left: auto;
}
.header__social a {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  position: relative;
}
.header__social svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0.65) translate(-75%, -75%);
  fill: #fff;
  transition: all 0.5s ease-out;
}
@media (hover: hover) {
  .header__social a:hover svg {
    fill: #00AF9A;
  }
}

.header__lang {
  position: absolute;
  top: 52px;
  transform: rotate(-90deg) translateY(100%);
  right: -30px;
  display: flex;
}
.header__lang a {
  color: #fff;
  font-size: 9px;
  display: inline-block;
  padding: 10px 5px;
  text-transform: uppercase;
}
.header__lang a.active {
  font-weight: 700;
  color: #00AF9A;
}
.header__lang a:hover {
  color: #00AF9A;
}

.link__recrutation {
  display: inline-block;
  padding: 15px 20px;
  border: 1px solid #00AF9A;
  text-transform: uppercase;
  font-weight: 800;
  background: #00AF9A;
  color: #fff;
  font-size: 12px;
  transition: none;
  margin-left: 50px;
}
.link__recrutation:hover {
  background: #D00035;
  border-color: #D00035;
}
@media (max-width: 1480px) {
  .link__recrutation {
    padding: 10px;
  }
}

.header .link__reservation {
  margin-left: 50px;
}

.nav {
  margin-right: 70px;
}
.nav .nav__linkSvg {
  padding-left: 46px;
}
.nav .nav__linkSvg svg {
  position: absolute;
  top: 5px;
  left: 0;
}
.nav ul li {
  position: relative;
}
.nav ul li a {
  padding: 5px 20px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  font-weight: 900;
  position: relative;
  width: 100%;
  outline: none;
}
.nav ul ul a {
  white-space: normal;
}
@media (min-width: 1200px) {
  .nav > ul > li > a {
    font-size: 14px;
  }
}

@media (hover: hover) {
  .nav ul li a:hover {
    color: #00AF9A;
  }
  .nav ul li a:focus {
    text-decoration: underline;
  }

  .menu-is-open .nav ul li a:hover {
    color: #000;
  }
}
.menu-is-open .header__logo,
.menu-open .header__logo {
  color: #000;
  fill: #000;
}
.menu-is-open .header,
.menu-open .header {
  background: #00AF9A;
}
.menu-is-open .header__lang,
.menu-open .header__lang {
  display: none;
}
.menu-is-open .link__recrutation,
.menu-open .link__recrutation {
  background: #000;
  transition: none;
}
.menu-is-open .fixed-header .header__logo,
.menu-open .fixed-header .header__logo {
  color: #ffff;
  fill: #fff;
}
.menu-is-open .fixed-header .link__recrutation,
.menu-open .fixed-header .link__recrutation {
  background: #00AF9A;
}

.nav > ul {
  list-style: none;
  display: flex;
}
@media (max-width: 1480px) {
  .nav > ul {
    gap: 10px;
  }
  .nav > ul > li:not(:last-child):after {
    right: -9px;
  }
}
.nav > ul .nav__level-2 {
  display: none;
  position: fixed;
  z-index: -1;
  top: 0;
  width: 100vw;
  left: 0;
  padding-top: 100px;
  padding-bottom: 50px;
  background: #00AF9A;
  text-transform: uppercase;
}
.nav > ul .nav__level-2 ul,
.nav > ul .nav__level-2 strong {
  max-width: 1400px;
  padding-left: 344px;
  margin-left: auto;
  margin-right: auto;
}
.nav > ul .nav__level-2 span {
  font-weight: 400;
}
.nav > ul .nav__level-2 ul li {
  margin-left: -15px;
}
.nav > ul .nav__level-2 strong {
  color: #000;
  margin-bottom: 15px;
  font-size: 12px;
  display: none;
}
.nav > ul .nav__level-2 a {
  color: #fff;
  line-height: 1.4;
  font-size: 18px;
}

.nav-toggle {
  min-width: 50px;
  width: 50px;
  height: 50px;
  padding: 12px 0 12px 0;
  background: transparent;
  border: none;
  display: block;
  display: none;
  position: relative;
  top: 2px;
  margin-left: 40px;
}
.nav-toggle small {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  bottom: 2px;
  right: 0;
}

.nav-toggle span {
  width: 32px;
  height: 2px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 1px;
  left: 18px;
  position: absolute;
}
.nav-toggle span.one {
  top: 14px;
}
.nav-toggle span.two {
  top: 24px;
  width: 20px;
  margin-left: 12px;
}
.nav-toggle span.three {
  top: 33px;
}

.nav-toggle.open .two {
  display: none;
}
.nav-toggle.open .one {
  position: absolute;
  top: 23px;
  left: 18px;
  transform: rotate(45deg);
  margin: 0;
}
.nav-toggle.open .three {
  position: absolute;
  top: 23px;
  left: 18px;
  transform: rotate(-45deg);
  margin: 0;
}

@media (max-width: 1560px) {
  .header__lang {
    right: 15px;
  }

  .link__recrutation {
    margin-left: 30px;
    margin-right: 20px;
  }
}
@media (max-width: 1480px) {
  .nav {
    margin-right: 20px;
  }
  .nav ul li a {
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav > ul .nav__level-2 ul li {
    margin-left: -8px;
  }

  .link__recrutation {
    margin-left: 10px;
  }
}
@media (max-width: 1200px) {
  .header__logo {
    margin-right: 30px;
  }

  .subpage {
    padding-top: 80px;
  }

  .header__wrapper.container {
    max-width: 100%;
    padding-top: 20px;
    height: 80px;
  }

  .subpage .header__wrapper.container {
    padding-top: 0px;
  }
  .subpage .header__lang {
    top: 22px;
  }
  .subpage .nav-toggle {
    margin-right: 50px;
  }

  .header__lang {
    right: 50px;
    top: 108px;
  }

  .link__recrutation {
    margin-right: 0;
    margin-left: 40px;
  }

  .header__social {
    margin-left: auto;
  }

  .menu__item--has-children:before {
    content: "";
    position: absolute;
    top: 12px;
    right: 35px;
    display: block;
    width: 15px;
    height: 15px;
    border-left: 4px solid #fff;
    border-bottom: 4px solid #fff;
    transform: rotate(-45deg);
  }

  .nav-toggle {
    display: block;
  }

  .nav {
    position: absolute;
    left: 0;
    top: 78px;
    background: #00AF9A;
    width: 100%;
    display: none;
    padding: 20px 0;
    margin-right: 0;
  }
  .nav > ul > li > a {
    font-size: 22px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .nav > ul > li > a:before {
    top: 18px;
  }
  .nav ul {
    display: block;
  }
  .nav ul li {
    width: 100%;
  }
  .nav ul li a {
    width: 100%;
    padding-left: 30px;
    padding-right: 70px;
  }
  .nav ul li .nav__linkSvg {
    padding-left: 80px;
  }
  .nav ul li .nav__linkSvg svg {
    left: 30px;
    top: 13px;
  }
  .nav ul .nav__level-2 {
    position: relative;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .nav ul .nav__level-2 ul {
    padding-left: 60px;
    padding-right: 20px;
  }
  .nav ul .nav__level-2 ul a {
    color: #000;
    padding: 10px 0;
  }
  .nav ul .nav__level-2 strong {
    display: none;
  }
}
@media (max-width: 768px) {
  .header__social {
    display: none;
  }

  .link__recrutation {
    margin-left: auto;
  }
}
@media (max-width: 576px) {
  .header__logo {
    transform: scale(0.6) !important;
    position: relative;
    left: -35px;
    width: 120px;
    margin-right: 0;
  }

  .nav {
    height: calc(100vh - 85px);
    overflow: auto;
  }
  .nav > ul {
    padding-bottom: 50px;
  }

  .menu-is-fixed .nav {
    height: calc(100vh - 60px);
  }

  .nav-toggle {
    margin-left: 15px;
  }

  .subpage .nav-toggle {
    margin-right: 0;
  }
  .subpage .header__lang {
    top: 108px;
  }
}
@media (max-width: 400px) {
  .nav-toggle {
    margin-left: 5px;
  }
}
.breadcrumbs {
  margin-bottom: 50px;
}
.breadcrumbs ul {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumbs ul span,
.breadcrumbs ul a {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  padding: 5px 0;
  line-height: 1;
}
.breadcrumbs ul a {
  margin-right: 10px;
}
.breadcrumbs ul a:after {
  margin-left: 10px;
  content: "/";
}
.breadcrumbs ul a:focus, .breadcrumbs ul a:hover {
  outline: none;
  color: #00AF9A;
}
@media (max-width: 768px) {
  .breadcrumbs ul a,
.breadcrumbs ul span {
    font-size: 12px;
  }
}

.page__header {
  min-height: 280px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}
@media (max-width: 500px) {
  .page__header {
    min-height: 200px;
  }
}
.page__header .breadcrumbs,
.page__header .container {
  position: reltive;
  z-index: 10;
}
.page__header img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 1480px) {
  .page__header .container--md {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.page__header--bc {
  padding-top: 30px;
}
.page__header--bc .article__info {
  margin-top: 0;
  margin-bottom: 20px;
}

.page__header--film {
  padding-top: 30px;
}
.page__header--film:before {
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, black, rgba(0, 0, 0, 0));
  position: absolute;
  z-index: 2;
  display: block;
  top: 0;
  left: 0;
}
.page__header--film h1 {
  color: #fff;
  margin-bottom: 5px;
}
.page__header--film .breadcrumbs a,
.page__header--film .breadcrumbs span {
  color: rgba(255, 255, 255, 0.4);
}

.header__time {
  color: #fff;
  display: flex;
  font-size: 20px;
  gap: 40px;
}

.page__header--center {
  justify-content: center;
  padding-top: 40px;
}
.page__header--center h1 {
  margin-bottom: 0;
}

.page__header--flex {
  display: flex;
  gap: 30px;
  position: relative;
  padding-left: 135px;
}
.page__header--flex svg {
  position: absolute;
  fill: #00AF9A;
  color: #000;
  top: 50%;
  transform: translateY(-50%) scale(0.7);
  left: -20px;
}
.page__header--flex h1 {
  margin-top: 30px;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .page__header--flex {
    padding-left: 0;
    padding-top: 100px;
  }
  .page__header--flex svg {
    top: 40px;
    transform: translateY(-50%) scale(0.5);
    left: -30px;
  }
  .page__header--flex h1 {
    margin-top: 0;
  }
  .page__header--flex .ph__courseName {
    position: absolute;
    left: 90px;
    max-width: calc(100% - 90px);
    top: 40px;
    transform: translateY(-50%);
  }
}

.ph__courseName {
  color: #00AF9A;
}

@media (max-width: 576px) {
  .menu-open {
    overflow: hidden;
  }
}

.hover-back__txt {
  position: absolute;
  bottom: -100%;
  right: 0;
  padding: 20px 20px 0 20px;
  transition: all 0.5s ease-out;
  text-align: right;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  z-index: 20;
  width: 100%;
  max-width: 260px;
}

.hover-back figure:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgba(0, 175, 154, 0.3);
  z-index: 10;
  opacity: 0;
  transition: all 0.3s ease-out 0.3s;
}
.hover-back figure:before {
  position: absolute;
  right: 20px;
  bottom: -100%;
  z-index: 20;
  transition: all 0.3s ease-out;
  content: url("../img/arrow-right-red.svg");
}
@media (hover: hover) {
  .hover-back:hover figure:after {
    width: 100%;
    opacity: 1;
    transition: all 0.3 ease-out;
  }
  .hover-back:hover figure:before {
    bottom: 20px;
    transition: all 0.3s ease-out 0.3s;
  }
  .hover-back:hover .hover-back__txt {
    bottom: 60px;
    transition: all 0.3s ease-out 0.3s;
  }
}

.films__optionsBtn {
  width: 100%;
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .films__optionsBtn {
    display: none;
  }
}

@media (max-width: 576px) {
  .films__optionsSelects {
    display: none;
  }
  .films__optionsSelects .select__wrapper {
    margin-bottom: 10px;
    padding-top: 0;
  }
  .films__optionsSelects .select__wrapper .form__select {
    margin-bottom: 10px;
  }
  .films__optionsSelects .page__nav {
    overflow: visible;
    margin-bottom: 10px;
  }
  .films__optionsSelects .page__nav ul {
    flex-wrap: wrap;
    gap: 5px;
  }
  .films__optionsSelects .page__nav li {
    width: 100%;
  }
}
.films__optionsSelects option {
  background: #F4F5F0;
}

.nproduction .row {
  -moz-column-gap: 2px;
       column-gap: 2px;
}
@media (max-width: 992px) {
  .nproduction .row {
    -moz-column-gap: 14px;
         column-gap: 14px;
  }
}

.nproduction_item {
  max-width: 50%;
  max-width: calc(50% - 7px);
  flex: 0 0 50%;
  margin-bottom: 14px;
}
@media (min-width: 768px) {
  .nproduction_item {
    max-width: calc(25% - 2px);
    flex: 0 0 25%;
  }
}
.nproduction_item figure {
  padding-top: 140%;
}
.nproduction_item figure:after {
  background: rgba(0, 175, 154, 0.83);
}
.nproduction_item .hover-back__txt {
  font-size: 24px;
}

.nproduction2 .hover-back__txt {
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nproduction2 h3 {
  margin-top: 15px;
}
.nproduction2 .nproduction_item {
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .nproduction2 .nproduction_item {
    margin-bottom: 30px;
    max-width: calc(50% - 7px);
    flex: 0 0 50%;
  }
  .nproduction2 .nproduction_item figure {
    width: 100%;
    padding-top: 70%;
    background: #00AF9A;
  }
  .nproduction2 .nproduction_item figure img {
    width: 50%;
    left: 25%;
  }
  .nproduction2 .nproduction_item .hover-back__txt {
    width: 50%;
    left: 50%;
    bottom: 20px;
    font-size: 11px;
    font-weight: 400;
    padding: 20px 10px 0 10px;
  }
}
@media (max-width: 768px) {
  .nproduction2 .nproduction_item {
    max-width: 100%;
    flex: 0 0 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media (max-width: 576px) {
  .nproduction2 .nproduction_item h3 {
    margin-top: 10px;
  }
}

@media (hover: hover) {
  .nproduction__link:hover .btn4 span,
.nproduction__link:hover .btn4 a {
    background-size: 15% 2px;
    background-image: linear-gradient(#D00035, #D00035);
  }
}

.coach__list {
  list-style: none !important;
  padding-left: 0 !important;
}

.coach__link {
  border-right: 2px solid #fff;
}
.coach__link .img__wrapper {
  width: calc(100% - 2px);
}
@media (hover: hover) {
  .coach__link:hover figure:after {
    opacity: 1;
  }
  .coach__link:hover .coach__txt-back {
    bottom: 0;
  }
  .coach__link:hover .btn4 span {
    background-size: 15% 2px;
    background-image: linear-gradient(#00AF9A, #00AF9A);
  }
  .coach__link:hover .coach__img-back-wrapper {
    width: 100%;
  }
}

.coach__img-back {
  min-width: 335px;
}
@media (max-width: 1480px) {
  .coach__img-back {
    min-width: 275px;
  }
}
@media (max-width: 1200px) {
  .coach__img-back {
    min-width: 306px;
  }
}
@media (max-width: 992px) {
  .coach__img-back {
    min-width: 230px;
  }
}

.coach__img-back-wrapper {
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;
}
.coach__img-back-wrapper img {
  left: 0;
  top: 0;
  transform: none;
}

.coach__item {
  max-width: 50%;
  flex: 0 0 50%;
  margin-bottom: 0px;
}
.coach__item .btn4 {
  margin-top: 10px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .coach__item {
    max-width: 33.33%;
    flex: 0 0 33.33%;
    margin-bottom: 25px !important;
  }
}
@media (min-width: 1200px) {
  .coach__item {
    max-width: 25%;
    flex: 0 0 25%;
    margin-bottom: 50px !important;
  }
}
.coach__item img {
  transition: opacity 0.5s ease-in-out;
}
@media (max-width: 576px) {
  .coach__item .btn4 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.course__item {
  max-width: 50%;
  flex: 0 0 50%;
}
.course__item .btn4 {
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .course__item {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}
@media (min-width: 1200px) {
  .course__item {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

.bg--grey .course__link {
  background: #fff;
}
.bg--grey .coach__link {
  border-right-color: #F4F5F0;
}

.course__link {
  background: #F4F5F0;
  display: block;
  padding: 15px;
  height: 100%;
  position: relative;
}
.course__link:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  content: "";
  background: #00AF9A;
  display: block;
  z-index: 1;
  transition: all 0.5s ease-out;
}
.course__link .btn4 {
  position: relative;
  z-index: 10;
}
.course__link .img__wrapper {
  padding-top: 85%;
  z-index: 10;
}
.course__link .course__svg {
  color: #272727;
  transition: all 0.5s ease-out;
  fill: #00AF9A;
}
@media (max-width: 1480px) {
  .course__link .course__svg {
    transform: translate(-50%, -50%) scale(0.8);
  }
}
@media (max-width: 768px) {
  .course__link .course__svg {
    transform: translate(-50%, -50%) scale(0.5);
  }
}
@media (hover: hover) {
  .course__link:hover:after {
    width: 100%;
  }
  .course__link:hover .btn4 {
    color: #fff;
  }
  .course__link:hover .course__svg {
    color: #272727;
    fill: #fff;
  }
  .course__link:hover .btn4 span {
    background-size: 15% 2px;
    background-image: linear-gradient(#D00035, #D00035);
  }
}

@media (max-width: 992px) {
  .mnews__leftWrapper .container--half {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.mnews__left {
  background: #00AF9A;
  background-image: linear-gradient(#00AF9A, #000);
  padding: 70px 50px 50px 50px;
}
.mnews__left p {
  color: #fff;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 900;
  line-height: 1.4;
}
@media (min-width: 992px) {
  .mnews__left {
    background-image: linear-gradient(#00AF9A, #00AF9A, #000);
    margin: 110px 0 0 0;
    max-width: 570px;
  }
}

.mnews__top {
  text-align: center;
}
.mnews__top img {
  max-width: 100%;
  margin-bottom: 50px;
}

.date {
  color: #00AF9A;
  font-weight: 700;
}

.tabs__navlist {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.tabs__nav-trigger {
  background-color: transparent;
  color: rgba(51, 51, 51, 0.5);
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0;
  border: none;
  display: inline;
  background-position: 0px 93%;
  background-repeat: no-repeat;
  background-size: 0 4px;
  transition: all 0.3s ease-out;
  background-image: linear-gradient(rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5));
  font-family: "Poppins", sans-serif;
}
@media (hover: hover) {
  .tabs__nav-trigger:hover {
    background-size: 100% 4px;
  }
}
.tabs__nav-trigger[aria-selected=true] {
  background-size: 100% 4px;
  color: #000;
  background-image: linear-gradient(#000, #000);
}

.newsM__container {
  padding-top: 50px;
  padding-bottom: 40px;
}
@media (min-width: 992px) {
  .newsM__container {
    padding-left: 40px;
  }
}

.newsM__panel {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 1260px;
}
@media (min-width: 576px) {
  .newsM__panel {
    height: 500px;
  }
}

.tabs__panel {
  position: absolute;
  top: 0;
  width: 100%;
  left: -100%;
  transition: left 0.5s ease-in-out;
  z-index: 2;
  background: #F4F5F0;
  height: 100%;
}
.tabs__panel[aria-hidden=false] {
  left: 0;
  z-index: 5;
}

.newsM__item {
  display: block;
  margin-bottom: 35px;
  width: 100%;
}
.newsM__item .tagSmall__list {
  margin-top: -5px;
  margin-bottom: 5px;
}
.newsM__item .tagSmall__list li:before {
  background: #00AF9A;
}
.newsM__item .tagSmall__list a {
  color: #00AF9A;
}
.newsM__item .tagSmall__list a:hover {
  color: #D00035;
}
.newsM__item .article__info {
  align-items: flex-end;
  margin-bottom: 5px;
  margin-top: 0;
}
.newsM__item .article__info a {
  font-size: 14px;
}
.newsM__item .img__wrapper {
  padding-top: 50%;
  margin-bottom: 10px;
  cursor: pointer;
}
.newsM__item h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  font-size: 21px;
}
.newsM__item h3 span,
.newsM__item h3 a {
  background-image: linear-gradient(#00AF9A, #00AF9A);
  background-position: 0 93%;
  background-repeat: no-repeat;
  background-size: 0 2px;
  transition: all 0.3s ease-out;
}
.newsM__item p {
  line-height: 26px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(51, 51, 51, 0.8);
  font-size: 15px;
}
@media (min-width: 576px) {
  .newsM__item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
  }
  .newsM__item .img__wrapper {
    min-width: 200px;
    max-width: 200px;
    padding-top: 112px;
  }
  .newsM__item p {
    -webkit-line-clamp: 2;
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .newsM__item {
    gap: 20px;
  }
}
@media (hover: hover) {
  .newsM__item:hover h3 span,
.newsM__item:hover h3 a {
    background-size: 100% 2px;
  }
}

.newsM__data {
  color: #00AF9A;
  font-size: 14px;
  display: block;
}

.newsM__panel .btn2 {
  position: relative;
  left: 190px;
}

.method {
  overflow: hidden;
  position: relative;
}
.method .swiper-slide {
  background: #00AF9A;
  height: 460px;
  padding-left: 130px;
}
.method .swiper-slide h3,
.method .swiper-slide h4,
.method .swiper-slide p {
  color: #fff;
}
.method .swiper-slide h4 {
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 20px;
}
.method .swiper-slide h3 {
  font-size: 50px;
}
.method .swiper-slide p {
  margin-bottom: 20px;
  max-width: 460px;
}
.method .swiper-method {
  position: relative;
  z-index: 10;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-right: 50px;
}
.method .swiper-method:after {
  content: "";
  width: 100vw;
  height: 100%;
  display: block;
  right: 0;
  top: 0;
  background: #00AF9A;
  position: absolute;
  z-index: -1;
}
@media (min-width: 768px) {
  .method .container {
    padding-top: 100px;
    display: flex;
    align-items: flex-start;
  }
  .method .swiper-method {
    margin-top: 60px;
    max-width: 75%;
    min-width: 75%;
  }
}
@media (min-width: 992px) {
  .method {
    margin-top: 120px;
  }
  .method .swiper-method {
    margin-top: 60px;
    max-width: calc(50% + 100px);
    min-width: calc(50% + 100px);
  }
}
@media (max-width: 1480px) {
  .method .swiper-slide {
    padding-left: 80px;
  }
  .method .swiper-slide h3 {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .method .swiper-method {
    padding-right: 0px;
  }
  .method .swiper-method:after {
    right: 50%;
    transform: translateX(50%);
  }
}
@media (max-width: 576px) {
  .method .container {
    padding: 0;
  }
  .method .swiper-method {
    padding: 0;
  }
  .method .swiper-slide {
    padding: 30px 20px 30px 60px;
    height: 570px;
  }
  .method .swiper-slide h3 {
    font-weight: 400;
  }
  .method .swiper-slide p {
    font-size: 15px;
    line-height: 1.4;
  }
}

.method__title {
  color: rgba(51, 51, 51, 0.2);
  font-size: 206px;
  position: absolute;
  line-height: 1;
  left: 0;
  top: 0;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .method__title {
    display: none;
  }
}

.method__img {
  position: relative;
  left: -100px;
}
@media (max-width: 768px) {
  .method__img {
    width: 100%;
    left: 0;
  }
}

.swiper-method .swiper-pagination {
  top: 0;
  height: 400px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #00AF9A;
}
.swiper-method .swiper-pagination .swiper-pagination-bullet {
  display: block;
  height: 100px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@media (max-width: 1480px) {
  .swiper-method .swiper-pagination {
    width: 80px;
  }
}
@media (max-width: 576px) {
  .swiper-method .swiper-pagination {
    width: 60px;
    height: 570px;
    top: 0px;
  }
  .swiper-method .swiper-pagination .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.1);
    height: 114px;
  }
  .swiper-method .swiper-pagination .swiper-pagination-bullet-active {
    background: #000;
    color: #fff;
  }
}

.newsletter {
  padding-top: 50px;
  padding-bottom: 40px;
  background: #00AF9A;
  color: #fff;
}
.newsletter h2 {
  margin-bottom: 0;
  padding-right: 20px;
}
@media (min-width: 992px) {
  .newsletter h2 {
    padding-right: 80px;
  }
}

.newsletter__wrapper {
  display: flex;
  flex-wrap: wrap;
  text-align: flex-end;
  gap: 30px;
}

.newsletter__form {
  max-width: 100%;
  width: 500px;
  border-bottom: 1px solid #F4F5F0;
  justify-content: space-between;
  display: flex;
}
.newsletter__form input {
  background: none;
  color: #fff;
  border: none;
  padding: 10px 0;
  width: 300px;
  max-width: 85%;
  font-size: 1.125rem;
}
.newsletter__form input::-moz-placeholder {
  color: #fff;
}
.newsletter__form input:-ms-input-placeholder {
  color: #fff;
}
.newsletter__form input::placeholder {
  color: #fff;
}
@media (min-width: 992px) {
  .newsletter__form input {
    width: 400px;
  }
}
.newsletter__form .btn-form {
  background: none;
  border: none;
  width: 50px;
  height: 50px;
  text-align: right;
  padding: 0;
  position: relative;
}
.newsletter__form .btn-form:after {
  position: absolute;
  top: 20px;
  right: 0;
  content: url("../img/arrow-right-w.svg");
}

.form--newsletter-out {
  position: relative;
  max-width: 400px;
}
.form--newsletter-out .form__input {
  margin-bottom: 0;
  width: 100%;
  height: 50px;
}
.form--newsletter-out .form__input input:focus {
  outline-offset: 0;
}
@media (min-width: 576px) {
  .form--newsletter-out button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 19px 25px 20px 25px;
    height: 51px;
  }
}
@media (max-width: 576px) {
  .form--newsletter-out button {
    width: 100%;
  }
}

.bigP {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  background: #000;
}
.bigP h1,
.bigP h2 {
  max-width: 800px;
}
.bigP .bigP__titleContainer {
  margin-bottom: 120px;
}
@media (min-width: 1140px) {
  .bigP {
    min-height: 900px;
  }
  .bigP .bigP__titleContainer {
    margin-bottom: 150px;
  }
}
@media (max-width: 576px) {
  .bigP h1,
.bigP h2 {
    font-weight: 600;
  }
  .bigP .bigP__titleContainer {
    margin-bottom: 50px;
  }
}
.bigP .bigP__title {
  opacity: 0;
  z-index: 5;
  transition: none;
  position: absolute;
  transition: none;
  top: 0;
  left: 0;
}
.bigP .bigP__title.active {
  z-index: 10;
  -webkit-animation: titleBIg 10s linear;
          animation: titleBIg 10s linear;
}

.bigP__titleContainer {
  position: relative;
  min-height: 120px;
}

@-webkit-keyframes titleBIg {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes titleBIg {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.bigP__wrapperImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}
.bigP__wrapperImg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(#000, rgba(0, 0, 0, 0), #000);
  z-index: 12;
}
.bigP__wrapperImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 2s ease-in;
  opacity: 0;
  z-index: 2;
}
.bigP__wrapperImg img.active {
  opacity: 0.85;
  z-index: 10;
  transition: opacity 5s ease-in-out;
}

.bigP__logo {
  color: #fff;
  fill: #D00035;
  margin-bottom: 80px;
}

.bigP__wrapperTxt {
  position: relative;
  z-index: 15;
  color: #fff;
  padding-top: 250px;
  padding-bottom: 220px;
}
@media (max-width: 576px) {
  .bigP__wrapperTxt {
    padding-bottom: 60px;
    padding-top: 200px;
  }
}

.bigP__list {
  display: flex;
  gap: 20px;
  max-width: 1100px;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 992px) {
  .bigP__list {
    flex-wrap: wrap;
    row-gap: 40px;
    -moz-column-gap: 10px;
         column-gap: 10px;
  }
  .bigP__list li {
    max-width: calc(50% - 5px);
    flex: 0 0 50%;
  }
  .bigP__list li a {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .bigP__list li a {
    font-size: 14px;
    display: inline-block;
    line-height: 1.4;
    font-weight: 600;
  }
}

.bigP__red.bigP {
  min-height: auto;
  border-top: 1px solid #fff;
}
.bigP__red.bigP h1,
.bigP__red.bigP h2 {
  max-width: 800px;
}
.bigP__red.bigP .bigP__list {
  max-width: 800px;
}
.bigP__red.bigP .bigP__wrapperTxt {
  padding-bottom: 120px;
}
@media (max-width: 576px) {
  .bigP__red.bigP .bigP__logo {
    margin-bottom: 50px;
  }
  .bigP__red.bigP .bigP__wrapperTxt {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.swiper-opinion {
  height: 550px;
  background: #333;
}
.swiper-opinion .swiper-button-prev {
  right: calc(50% + 10px);
  bottom: 65px;
}
.swiper-opinion .swiper-button-next {
  right: calc(50% - 70px);
  bottom: 65px;
}
.swiper-opinion .swiper-pagination {
  left: calc(50% - 50px);
  color: #fff;
  transform: rotate(-90deg);
  font-size: 9px;
  bottom: 150px;
}
.swiper-opinion .swiper-pagination span:after {
  content: none;
}
.swiper-opinion .swiper-slide-active .opinion__item:first-child .container--half {
  left: 0;
}
.swiper-opinion .swiper-slide-active .opinion__item:nth-child(2) .container--half {
  right: 0;
}
.swiper-opinion .swiper-slide-active .opinion__item .opinion__imgWrapper {
  width: 100%;
}
.swiper-opinion .swiper-slide-active .opinion__item .container--half {
  opacity: 1;
}

.opinion__wrapper {
  display: flex;
  position: relative;
}
@media (min-width: 768px) {
  .opinion__wrapper:after {
    content: "";
    display: block;
    border-right: 1px solid #fff;
    position: absolute;
    height: 100%;
    left: 50%;
    top: 0;
    z-index: 80;
  }
}
.opinion__wrapper .container--half {
  position: relative;
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 80px;
  opacity: 0;
  transition: left 0.5s ease-out 0.5s, right 0.5s ease-out 0.5s, opacity 0.5s ease-out 0.5s;
  z-index: 30;
}

.opinion__item {
  max-width: 50%;
  flex: 0 0 50%;
  position: relative;
  height: 550px;
  color: #fff;
}
.opinion__item:after {
  content: "";
  display: block;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.opinion__item span,
.opinion__item strong {
  display: block;
  text-align: right;
}
.opinion__item:first-child .container--half {
  margin-right: 0;
  padding-right: 100px;
  left: -100px;
}
.opinion__item:first-child .opinion__imgWrapper {
  left: initial;
  right: 0;
}
.opinion__item:first-child img {
  left: initial;
  right: 0;
}
.opinion__item:nth-child(2) .container--half {
  margin-left: 0;
  padding-left: 100px;
  right: -100px;
}

.opinion__imgWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  overflow: hidden;
  transition: width 0.5s ease-out;
}
.opinion__imgWrapper img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0;
  left: 0;
  height: 100%;
  width: 50vw;
}

.opinion__txt {
  margin-bottom: 20px;
  position: relative;
  line-height: 1.5;
}
.opinion__txt:before {
  content: url("../img/quote.svg");
  position: absolute;
  left: 0;
  top: -40px;
}

@media (max-width: 768px) {
  .swiper-opinion {
    height: 850px;
  }

  .opinion__wrapper {
    display: block;
  }

  .opinion__imgWrapper img {
    width: 100vw;
  }

  .opinion__item {
    max-width: 100%;
    flex: 0 0 100%;
    height: 390px;
  }
  .opinion__item:first-child {
    margin-bottom: 70px;
  }
  .opinion__item .container--half {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 30px;
  }
  .opinion__item span,
.opinion__item strong {
    font-size: 12px;
  }

  .swiper-opinion .swiper-button-prev {
    bottom: 395px;
    right: initial;
    left: 20px;
  }
  .swiper-opinion .swiper-button-next {
    bottom: 395px;
    left: initial;
    right: 20px;
  }
  .swiper-opinion .swiper-pagination {
    transform: translateX(-50%);
    bottom: 415px;
    left: 50%;
    font-size: 14px;
  }
}
.intro__imgWrapper {
  display: flex;
}
.intro__imgWrapper img {
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 992px) {
  .intro__imgWrapper img {
    width: 100%;
    max-height: 600px;
  }
}

.intro__txt {
  padding-top: 90px;
  padding-bottom: 40px;
}
.intro__txt p {
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .intro__txt {
    padding-top: 50px;
  }
}

.course2__list li {
  padding-bottom: 15px;
  padding-top: 20px;
  border-bottom: 1px solid #ddd;
}
@media (max-width: 576px) {
  .course2__list li {
    padding-bottom: 25px;
    padding-top: 30px;
  }
}

.course2__txt {
  width: 100%;
}
.course2__txt h3 {
  margin-bottom: 10px;
  font-size: 21px;
}
.course2__txt p {
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course2__list {
  list-style: none !important;
  padding-left: 0 !important;
}

.course2__link {
  display: flex;
  align-items: center;
  gap: 50px;
  background-image: linear-gradient(#F4F5F0, #F4F5F0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease-out;
}
.course2__link svg {
  fill: #00AF9A;
}
.course2__link .img__wrapper {
  flex: 0 0 225px;
  max-width: 225px;
  height: 225px;
  padding-top: 0;
}
.course2__link .img__wrapper:first-of-type {
  background: #F4F5F0;
}
@media (hover: hover) {
  .course2__link:hover {
    background-size: 100% 100%;
  }
  .course2__link:hover .btn2:after {
    background: #00AF9A;
    width: 20%;
  }
}
@media (max-width: 1200px) {
  .course2__link {
    gap: 30px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .course2__link .img__wrapper:nth-of-type(2) {
    display: none;
  }
}
@media (max-width: 768px) {
  .course2__link {
    flex-wrap: wrap;
    gap: 0;
    background: none;
    padding-bottom: 10px;
  }
  .course2__link .course2__txt {
    width: 100%;
    order: 3;
    margin-top: 20px;
  }
  .course2__link .img__wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 50%;
    height: 0;
  }
  .course2__link .img__wrapper svg {
    transform: translate(-50%, -50%) scale(0.5);
  }
  .course2__link p {
    -webkit-line-clamp: 5;
  }
}

.green__wrapper {
  background: #00AF9A;
  padding-top: 85px;
  padding-bottom: 85px;
  color: #fff;
  font-size: 24px;
}
@media (max-width: 576px) {
  .green__wrapper {
    font-size: 21px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.select__wrapper {
  display: flex;
  -moz-column-gap: 30px;
       column-gap: 30px;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-top: 10px;
}
.select__wrapper .form__select {
  margin-top: 0;
}
.select__wrapper .form__select:after {
  background: #F4F5F0;
}
.select__wrapper .btn {
  margin-bottom: 25px;
}

.page__nav {
  margin-bottom: 20px;
  overflow: auto;
}
.page__nav input {
  opacity: 0;
  position: absolute;
  z-index: -1000;
  overflow-y: hidden;
  width: 0;
  height: 0;
}
.page__nav label {
  cursor: pointer;
}
.page__nav ul {
  display: flex;
  gap: 0 20px;
}
.page__nav ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.page__nav ul a,
.page__nav ul button,
.page__nav ul label {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  color: rgba(51, 51, 51, 0.5);
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0;
  border: none;
  display: inline;
  line-height: 1;
  background-position: 0px 93%;
  background-repeat: no-repeat;
  background-size: 0 4px;
  transition: all 0.3s ease-out;
  background-image: linear-gradient(rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5));
  vertical-align: bottom;
  line-height: 1;
  align-items: flex-start;
}
@media (hover: hover) {
  .page__nav ul a:hover,
.page__nav ul button:hover,
.page__nav ul label:hover {
    background-size: 100% 4px;
  }
}
.page__nav ul a[aria-selected=true], .page__nav ul a.active,
.page__nav ul button[aria-selected=true],
.page__nav ul button.active,
.page__nav ul label[aria-selected=true],
.page__nav ul label.active {
  background-size: 100% 4px;
  color: #000;
  background-image: linear-gradient(#000, #000);
}
@media (max-width: 576px) {
  .page__nav ul {
    gap: 15px;
  }
  .page__nav ul a,
.page__nav ul button,
.page__nav ul label {
    font-size: 16px;
    white-space: nowrap;
  }
}
.page__nav ul input:checked + label {
  background-size: 100% 4px;
  color: #000;
  background-image: linear-gradient(#000, #000);
}

.tag__list {
  display: flex;
  margin-top: 25px;
  flex-wrap: wrap;
}
.tag__list li {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}
.tag__list li:before {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  top: 50%;
  margin-top: -2px;
  left: -2px;
  position: absolute;
  background: rgba(51, 51, 51, 0.5);
}
.tag__list li a {
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .tag__list li a {
    font-size: 20px;
  }
}

.tagSmall__list {
  display: flex;
  flex-wrap: wrap;
}
.tagSmall__list li {
  padding-right: 5px;
  padding-left: 5px;
  position: relative;
}
.tagSmall__list li:before {
  content: "";
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  top: 50%;
  margin-top: -1px;
  left: -1px;
  position: absolute;
  background: rgba(51, 51, 51, 0.5);
}
.tagSmall__list li a {
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgba(51, 51, 51, 0.5);
}
@media (hover: hover) {
  .tagSmall__list li a:hover {
    color: #00AF9A;
  }
}

.tags {
  color: #333;
}

.article__autor {
  display: block;
  color: rgba(51, 51, 51, 0.5);
}

.article__list {
  display: flex;
  padding-top: 70px;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 80px;
}

.article__item {
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.article__item .date {
  white-space: nowrap;
}
.article__item img {
  cursor: pointer;
}
.article__item h3 {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 20px;
  margin-top: 10px;
}
@media (max-width: 1480px) {
  .article__item h3 {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .article__item h3 {
    font-size: 21px;
  }
}
@media (min-width: 992px) {
  .article__item {
    flex: 0 0 50%;
    max-width: calc(50% - 15px);
  }
}

.article__info {
  display: flex;
  flex-wraP: wrap;
  gap: 10px 20px;
}
@media (max-width: 576px) {
  .article__info {
    gap: 5px 20px;
    margin-bottom: 15px;
  }
}
.article__info a {
  color: rgba(51, 51, 51, 0.5);
}
.article__info a:hover {
  color: #00AF9A;
}

.article__box .tagSmall__list {
  gap: 10px;
  margin-top: 15px;
}
.article__box .tagSmall__list li {
  padding: 0;
}
.article__box .tagSmall__list li:before {
  content: none;
}
.article__box .tagSmall__list a {
  background: #00AF9A;
  color: #fff;
  padding: 3px 15px;
  display: block;
}
@media (hover: hover) {
  .article__box .tagSmall__list a:hover {
    color: #fff;
    background: rgba(0, 175, 154, 0.83);
  }
}

.article__item--first {
  background: #000;
}
.article__item--first .img__wrapper {
  padding-top: 56%;
}
.article__item--first .article__box {
  width: 100%;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 5;
}
@media (min-width: 992px) {
  .article__item--first .article__box {
    padding: 30px 50px 100px 50px;
  }
}
.article__item--first .article__summary {
  display: none;
}
.article__item--first h3 {
  color: #fff;
  font-weight: 400;
}
.article__item--first .article__autor {
  color: rgba(255, 255, 255, 0.7);
}

.article__item--second .img__wrapper {
  padding-top: 56%;
  margin-bottom: 30px;
}
@media (max-width: 992px) {
  .article__item--second .img__wrapper {
    margin-bottom: 15px;
  }
}

.article__item--third {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  -moz-column-gap: 30px;
       column-gap: 30px;
}
.article__item--third .img__wrapper {
  min-width: 158px;
  max-width: 158px;
  padding-top: 158px;
}
.article__item--third .article__summary {
  display: none;
}
.article__item--third h3 {
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 0;
}
.article__item--third .article__info {
  font-size: 14px;
}
@media (max-width: 576px) {
  .article__item--third {
    -moz-column-gap: 15px;
         column-gap: 15px;
    align-items: stretch;
    margin-bottom: 0;
  }
  .article__item--third .img__wrapper {
    margin-left: -30px;
    padding-top: 158px;
    max-width: 120px;
    min-width: 120px;
  }
  .article__item--third h3 {
    font-size: 19px;
  }
}

.article__intro {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}
.article__intro p {
  margin-bottom: 0;
}

.article__wrapper {
  padding-top: 0px;
}
.article__wrapper .page__nav {
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  top: 0px;
  padding-top: 70px;
  padding-bottom: 10px;
  margin-bottom: 0;
}
.article__wrapper ol,
.article__wrapper ul,
.article__wrapper p {
  margin-bottom: 15px;
  font-size: 18px;
}
.article__wrapper ul,
.article__wrapper ol {
  list-style: initial;
  padding-left: 30px;
}
.article__wrapper ol {
  list-style: decimal;
}
.article__wrapper li {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .article__wrapper h2 {
    margin-bottom: 50px;
  }
  .article__wrapper h3 {
    margin-bottom: 15px;
  }
  .article__wrapper ol,
.article__wrapper ul,
.article__wrapper p {
    margin-bottom: 20px;
  }
}
.article__wrapper .article__txt a {
  text-decoration: underline;
}
@media (hover: hover) {
  .article__wrapper .article__txt a:hover {
    color: #00AF9A;
  }
}

.article--date {
  padding-top: 60px;
  margin-top: -60px;
}
.article--date > strong {
  font-size: 50px;
  margin-bottom: 40px;
  display: block;
}
@media (max-width: 576px) {
  .article--date > strong {
    font-size: 34px;
    margin-bottom: 25px;
  }
}
.article--date .coach__item {
  margin-bottom: 30px !important;
}

.page--small strong {
  font-weight: 500;
}
.page--small ol,
.page--small ul,
.page--small p {
  font-size: 15px;
  color: rgba(51, 51, 51, 0.5);
}
.page--small li {
  margin-bottom: 8px;
}

.list--decor {
  list-style: none !important;
  padding-left: 0 !important;
  margin-bottom: 50px !important;
}
@media (min-width: 1200px) {
  .list--decor {
    display: flex;
  }
  .list--decor > ul {
    padding-right: 30px;
    margin-bottom: 0 !important;
    min-width: 30%;
  }
}
.list--decor > ul {
  padding-left: 30px;
  position: relative;
  margin-bottom: 30px !important;
}
.list--decor > ul:after {
  content: "";
  background: #00AF9A;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 9px;
  background: #00AF9A;
}
.list--decor > ul p {
  margin-bottom: 0;
}
.list--decor ul {
  list-style: none;
}
.list--decor ul li {
  margin-bottom: 0;
}
.list--decor ul h4 {
  font-size: 21px;
  font-weight: 900;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .list--decor ul h4 {
    font-size: 24px;
  }
}
@media (min-width: 1480px) {
  .list--decor ul h4 {
    white-space: nowrap;
  }
}

.readmore__list {
  list-style: none !important;
  padding-left: 0 !important;
}
.readmore__list li {
  margin-bottom: 0;
}

.readmore__link {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.readmore__link .img__wrapper {
  max-width: 140px;
  flex: 0 0 140px;
  padding-top: 78px;
}
.readmore__link .img__wrapper img {
  -o-object-fit: contain;
     object-fit: contain;
}
.readmore__link h3 {
  font-size: 21px;
  font-weight: 400;
}
.readmore__link p {
  margin-bottom: 0;
  font-size: 18px;
  color: rgba(51, 51, 51, 0.8);
}
@media (hover: hover) {
  .readmore__link:hover h3 span {
    background-size: 100% 4px;
  }
}
@media (max-width: 768px) {
  .readmore__link {
    gap: 15px;
    align-items: flex-start;
  }
  .readmore__link .img__wrapper {
    padding-top: 67px;
    max-width: 120px;
    min-width: 120px;
  }
  .readmore__link p {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .readmore__link {
    flex-wrap: wrap;
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .readmore__link .img__wrapper {
    padding-top: 56%;
    max-width: 100%;
    min-width: 100%;
  }
}

.partners__list {
  list-style: none !important;
  padding-left: 0 !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px 30px;
}
.partners__list li img {
  max-width: 100%;
  height: 40px;
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 576px) {
  .partners__list {
    gap: 20px;
  }
  .partners__list li {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .partners__list {
    padding-right: 200px;
  }
}

.team__list {
  list-style: none !important;
  padding-left: 0 !important;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 50px;
}

.team__item {
  flex: 0 0 50%;
  max-width: calc(50% - 25px);
  display: flex;
  gap: 30px;
  padding-right: 30px;
}
.team__item h5,
.team__item h4 {
  color: #000;
}
.team__item h4,
.team__item h5,
.team__item a {
  font-size: 18px;
  font-weight: 400;
}
.team__item h4 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.team__item h5 {
  margin-bottom: 10px;
}
.team__item a {
  color: #00AF9A;
}
.team__item .img__wrapper {
  max-width: 50%;
  min-width: 50%;
  padding-top: 50%;
}
@media (max-width: 1480px) {
  .team__item .img__wrapper {
    max-width: 40%;
    min-width: 40%;
    padding-top: 40%;
  }
}
@media (max-width: 1200px) {
  .team__item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .team__item .img__wrapper {
    max-width: 30%;
    min-width: 30%;
    padding-top: 30%;
  }
}
@media (max-width: 768px) {
  .team__item {
    gap: 20px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .team__item h4 {
    margin-bottom: 10px;
    margin-top: 0;
  }
  .team__item h5 {
    margin-bottom: 0;
  }
  .team__item h5,
.team__item a {
    font-size: 14px;
  }
  .team__item .img__wrapper {
    max-width: 30%;
    min-width: 30%;
    padding-top: 30%;
  }
}
@media (max-width: 768px) {
  .team__item .img__wrapper {
    margin-left: -30px;
    max-width: 40%;
    min-width: 40%;
    padding-top: 40%;
  }
}

.artpag__list {
  padding-top: 50px;
  padding-bottom: 70px;
  margin-bottom: 50px;
  position: relative;
  gap: 30px;
}
@media (min-width: 992px) {
  .artpag__list {
    display: flex;
  }
  .artpag__list li:not(.artpag__last) {
    flex: 0 0 50%;
    max-width: calc(50% - 15px);
  }
}

.artpag__last {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.artpag__link {
  display: block;
  margin-bottom: 30px;
}
.artpag__link strong {
  display: block;
  margin-bottom: 20px;
  font-size: 24px;
}
.artpag__link .img__wrapper {
  padding-top: 56px;
  max-width: 100px;
  min-width: 100px;
}
.artpag__link .date {
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
  font-weight: 400;
}
.artpag__link h3 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0px;
}
.artpag__link h3 span {
  background-position: 0 80%;
}
@media (hover: hover) {
  .artpag__link:hover svg {
    stroke: #00AF9A;
  }
  .artpag__link:hover h3 span {
    background-size: 100% 4px;
  }
}
@media (min-width: 992px) {
  .artpag__link {
    gap: 30px;
  }
  .artpag__link .date {
    margin-top: -5px;
  }
  .artpag__link .img__wrapper {
    padding-top: 78px;
    max-width: 140px;
    min-width: 140px;
  }
}
@media (max-width: 576px) {
  .artpag__link h3 {
    font-size: 16px;
  }
  .artpag__link .artpag__wrapper {
    gap: 10px;
    position: relative;
    flex-wrap: wrap;
  }
  .artpag__link .artpag__txt {
    position: static;
    padding-bottom: 0;
    width: 100%;
  }
  .artpag__link .date {
    top: 8px;
    position: absolute;
  }
  .artpag__link .img__wrapper {
    order: -1;
  }
  .artpag__link svg {
    bottom: initial;
    top: 32px;
  }
  .artpag__link.artpag__link--left svg {
    left: 120px;
  }
  .artpag__link.artpag__link--left .date {
    left: 120px;
  }
  .artpag__link.artpag__link--right svg {
    right: 120px;
  }
  .artpag__link.artpag__link--right .date {
    right: 120px;
  }
  .artpag__link.artpag__link--right .artpag__wrapper {
    justify-content: flex-end;
  }
}

.artpag__wrapper {
  display: flex;
  gap: 20px;
}

.artpag__txt {
  padding-bottom: 30px;
  position: relative;
  width: 100%;
}
.artpag__txt svg {
  stroke: #333;
  position: absolute;
  transition: all 0.3s ease-out;
  bottom: 10px;
}

.artpag__link--right {
  margin-left: auto;
  text-align: right;
}
.artpag__link--right svg {
  right: 0;
}

.artpag__link--left {
  margin-right: auto;
}
.artpag__link--left svg {
  left: 0;
}

.film__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-left: 9px solid #00AF9A;
  padding: 5px 20px;
  gap: 20px;
  margin-bottom: 50px;
  width: 100%;
}
.film__header strong span {
  font-weight: 400;
  margin-right: 10px;
}
@media (max-width: 1480px) {
  .film__header {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 15px 10px 15px 15px;
  }
}

.film__courses {
  display: flex;
  gap: 30px;
  align-items: center;
  font-weight: 900;
  margin-left: auto;
}
@media (max-width: 1480px) {
  .film__courses {
    margin-left: initial;
  }
}
@media (max-width: 768px) {
  .film__courses {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
  }
}
.film__courses > div {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.film__course {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
}
.film__course .img__wrapper {
  max-width: 60px;
  min-width: 60px;
  padding-top: 60px;
  margin-right: 10px;
}
.film__course .img__wrapper svg {
  transform: translate(-50%, -50%) scale(0.3);
  fill: #00AF9A;
  color: #000;
}

.film__section2 {
  margin-bottom: 40px;
}
.film__section2 img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .film__section2 {
    display: flex;
    gap: 30px;
    align-items: flex-start;
  }
  .film__section2 img {
    max-width: 312px;
  }
  .film__section2 p {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .film__section3 {
    display: flex;
    gap: 50px;
  }
}

.film__list {
  list-styLe: none !important;
  padding-left: 0 !important;
  margin-bottom: 40px !important;
}
@media (min-width: 768px) {
  .film__list {
    flex: 0 0 66.66%;
    -moz-column-gap: 40px;
         column-gap: 40px;
    -moz-column-count: 2;
         column-count: 2;
  }
}
.film__list span {
  display: inline-block;
  min-width: 150px;
  max-width: 150px;
}
.film__list li {
  display: flex;
  font-size: 14px;
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
}
.film__list .js-novisible {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
  transition: max-height 0 ease-out;
}
.film__list .js-visible {
  max-height: 300px;
  margin-bottom: 10px;
}
.film__list .link--mainBold {
  border: none;
  padding: 10px 0;
  background: none;
}
.film__list .link--mainBold span {
  min-width: auto;
  max-width: auto;
  border: none;
}
.film__list .link--mainBold span.hide {
  display: none;
}

.film__acc {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 30px;
}

.article4__wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
}

.article4__left {
  width: 100%;
}
@media (min-width: 768px) {
  .article4__left .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .article4__left .coach__item {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 1200px) {
  .article4__left .coach__item {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}

.article4__nav {
  width: 100%;
  font-size: 24px;
}
.article4__nav .btn {
  width: 100%;
}
.article4__nav a {
  color: rgba(51, 51, 51, 0.8);
  line-height: 2;
  width: 100%;
  display: block;
}
.article4__nav a:target, .article4__nav a.active {
  color: #00AF9A;
}
@media (hover: hover) {
  .article4__nav a:hover {
    color: #00AF9A;
  }
}

.article4__navT {
  display: none;
}

@media (max-width: 768px) {
  .article4__nav a {
    text-align: center;
  }
  .article4__nav ul {
    padding-top: 20px;
    background: #fff;
    display: none;
    max-height: 240px;
    overflow-y: auto;
  }

  .article4__left {
    padding-top: 30px;
  }
}
@media (max-width: 576px) {
  .coach__list--2.row {
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media (min-width: 768px) {
  .article4__wrapper {
    flex-wrap: nowrap;
  }

  .article4__nav {
    max-width: 230px;
    flex: 0 0 230px;
    padding-left: 30px;
  }
  .article4__nav .btn {
    display: none;
  }
  .article4__nav .article4__navT {
    display: block;
  }

  .article4__left {
    order: -1;
  }
}
@media (min-width: 1200px) {
  .article4__nav {
    padding-left: 80px;
  }
}
.article4__sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
}

.laureate__item {
  margin-bottom: 0 !important;
}
.laureate__item img {
  max-width: 100%;
}
.laureate__item h2 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 21px;
  line-height: 1.2;
}
.laureate__item h3,
.laureate__item h4,
.laureate__item h5,
.laureate__item h6 {
  font-weight: 400;
}
.laureate__item li ol {
  list-style: decimal;
  padding-left: 20px;
}
.laureate__item li ol li {
  padding-left: 10px;
}

.laureate__list {
  padding-left: 0 !important;
  list-style: none !important;
}

@media (max-width: 992px) {
  .laureate__item {
    padding-bottom: 50px;
  }

  .laureate__left {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .laureate__item {
    margin-left: -30px;
    margin-right: -30px;
    display: flex;
    position: relative;
  }
  .laureate__item:nth-child(even) img {
    display: block;
    margin-left: auto;
  }
  .laureate__item h2 {
    font-size: 36px;
  }
  .laureate__item:before {
    content: "";
    width: 1px;
    background: #707070;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
  }
  .laureate__item:after {
    content: "";
    width: 11px;
    border: 1px solid #707070;
    background: #fff;
    height: 11px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-5px);
  }
  .laureate__item:nth-child(odd) .laureate__right {
    order: -1;
  }

  .laureate__left,
.laureate__right {
    max-width: 50%;
    min-width: 50%;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .laureate__item {
    margin-left: -50px;
    margin-right: -50px;
  }

  .laureate__left,
.laureate__right {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.accordion__panel button:not(.accordion__btn2) {
  background: transparent;
  width: 100%;
  border: none;
}

.accordion__panel button .hide {
  display: inline-block;
}
.accordion__panel button .open {
  display: none;
}

.accordion__panel button.active .open {
  display: inline-block;
}
.accordion__panel button.active .hide {
  display: none;
}

.accordion__content {
  overflow: hidden;
  margin-bottom: 30px;
  transition: height 0.3s ease-out;
}

.img__wyswig {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.img__wyswig img {
  max-width: 100%;
  margin-bottom: 0;
  vertical-align: middle;
}
.img__wyswig span {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3px 10px;
  max-width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  font-style: italic;
  display: inline-block;
  text-align: right;
}