@keyframes goTop {
    0% {
        top: 30px;
    }

    25% {
        top: 25px;
    }

    50% {
        top: 30px;
    }

    75% {
        top: 35px;
    }

    100% {
        top: 30px;
    }
}

.go-top {
    position: absolute;
    top: 30px;
    right: 80px;
    z-index: 98;
    padding-right: 80px;
    font-weight: 700;
    font-size: 9px;
    text-transform: uppercase;
    transform: rotate(-90deg);
    color: #fff;
    background: transparent;
    border: none;

    &:before {
        content: "";
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: block;
        background: #FCFCFA;
        position: absolute;
        top: -30px;
        right: 0;
        transition: background 0.3s ease-in-out;
    }

    &:after {
        content: url("../img/arrow-top.svg");
        position: absolute;
        top: -14px;
        right: 30px;
        transform: rotate(90deg);
    }

    @media(hover:hover) {
        &:hover {
            color: #fff;
            animation: goTop 1s ease-in-out infinite;
            transition: top 0.3s ease-in-out;

            &:before {
                background: $main-light;
            }
        }
    }

    @media(max-width:$grid-breakpoints-xl) {
        right: 0px;
    }


}


.go-bottom {
    position: absolute;
    bottom: 10px;
    left: 0px;
    z-index: 100;
    padding-left: 30px;
    font-weight: 700;
    font-size: 9px;
    text-transform: uppercase;
    transform: rotate(-90deg);
    color: $main;
    z-index: 30;


    &:after {
        content: url("../img/arrow-down-s-m.svg");
        position: absolute;
        top: -4px;
        left: 0px;
        transform: rotate(90deg);
    }

    @media(hover:hover) {
        &:hover {
            color: $main;
        }
    }

    @media(max-width:$grid-breakpoints-lg) {
        bottom: 40px;
        padding-left: 20px;
        left: -20px
    }



}

.footer {
    background: #333333;
    position: relative;
    color: #fff;
}


.footer__top {
    padding-top: 70px;
    padding-bottom: 30px;

    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        >li {
            position: relative;

            a {
                padding: 5px;
                font-size: 12px;
                text-transform: uppercase;
                color: #fff;
                font-weight:700;

                @media(hover:hover) {
                    &:hover {
                        color: $main;
                    }
                }
            }
        }
    }

    @media(max-width:$grid-breakpoints-xl) {
        padding-top: 30px;

        ul {
            row-gap: 0px;
            column-gap: 15px;


            >li:not(:last-child):after {
                right: -9px;
            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        ul {
            flex-direction: column;
            justify-content: left;
            padding-left: 30px;

            li {
                &:after {
                    left: -25px;
                    right: initial;
                }

                &:last-child:after {
                    content: "";
                }
            }
        }
    }


}


.footer__center {
    display: grid;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 35px;

    svg {

        fill: #fff !important;
    }

    @media(min-width:$grid-breakpoints-lg) {
        grid-template-columns: 250px 340px 1fr;
        gap: 20px;
    }

    @media(min-width:$grid-breakpoints-xl) {
        gap: 60px;
        grid-template-columns: 340px 400px 1fr;
    }

    @media(max-width:$grid-breakpoints-lg) {
        text-align: center;
        padding-bottom: 10px;
        padding-top: 10px;

        .footer__social img {
            max-width: 30px;
        }
    }
}


.footer__contact {
    font-size: 14px;

    span {
        &:after {
            content: "";
            width: 88px;
            height: 1px;
            background: $bg-grey;
            display: inline-block;
            margin-left: 10px;
            margin-right: 5px;
            position: relative;
            bottom: 4px;

        }
    }

    a {
        color: $main;
        font-weight: 700;

        @media(hover:hover) {
            &:hover {
                color: $red;
            }
        }
    }

    p:nth-child(2) {
        span:after {
            width: 63px;

            @media(max-width:$grid-breakpoints-sm) {
                width: 13px;
            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        p {
            span:after {
                width: 10px;

            }
        }
    }

}


.footer__bottom {
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 16px;
    background: #000;

    ul {
        display: flex;
        justify-content: center;
        padding-left: 0;
        list-style: none;
        gap: 40px;
        align-items: center;
        padding-right:0;
    }

    li {
        text-align: center;
    }

    img {
        max-height: 40px;
        width: auto;
        max-width: 100%;
        object-fit: contain;
    }

    @media(max-width:$grid-breakpoints-lg) {
        ul {
            gap: 20px;
        }
    }

    @media(max-width:$grid-breakpoints-md) {
        ul {
            flex-wrap: wrap;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        ul {
            li {
                flex: 0 0 50%;
                max-width: calc(50% - 10px);
            }

            .sm-100 {
                flex: 0 0 100%;
                max-width: 100%
            }
        }
    }
}


.footer__social {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 25px;

    svg {

        fill: #fff !important;
    }

    @media(hover:hover) {
        a:hover {
            svg {
                fill: $main !important;
            }
        }
    }

    @media(min-width: $grid-breakpoints-md) and(max-width:$grid-breakpoints-lg) {
        flex-wrap: wrap;
    }
}

.footer__brand {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #9A9A9A;
    padding-bottom: 30px;
    padding-top: 20px;

    a {
        display: inline-block;
        padding: 5px;
    }

    @media(max-width: $grid-breakpoints-lg) {

        justify-content: center;


    }
}