@import "components/variables";


.menu-open .header {
    background: $main;
}


@keyframes fixedMenuDown {
    from {
        top: -100px
    }

    to {
        top: 0
    }
}

.fixed-header-scroll {
    background: #000 !important;
    position: fixed;
    top: 0;
    animation-name: fixedMenuDown;
    animation-duration: .5s;

    .header__lang {
        display: none;
    }

    .header__logo {
        transform: scale(0.7);
        color: #fff !important;
        fill: #fff !important;
    }

    .header__wrapper {
        height: 60px !important;
        padding-top: 0 !important;
    }

    .nav {
        top: 60px;
    }

    .link__recrutation {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    @media (min-width: $grid-breakpoints-xxl) {
        .nav>ul .nav__level-2 {
            padding-top: 60px;

        }
    }
}



.header__wrapper {
    display: flex;
    position: relative;
    z-index: 100;
    height: 110px;
    padding-top: 40px;
    align-items: center;
    justify-content: space-between;
}

.subpage {
    padding-top: 110px;

    .header__wrapper {
        padding-top: 10px;
    }

    .header {
        background: #000;
    }

    .header__lang {
        top: 42px;
    }

}

.header__logo {
    color: #fff;
    fill: $main;
    margin-right: 100px;
    transition: none;

    svg {
        transition: none;
    }
}

.header__social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    margin-left: auto;

    a {
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        position: relative;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(0.65) translate(-75%, -75%);
        fill: #fff;
        transition: all 0.5s ease-out;
    }

    @media(hover:hover) {
        a:hover {
            svg {
                fill: $main;
            }
        }
    }
}


.header__lang {
    position: absolute;
    top: 52px;
    transform: rotate(-90deg) translateY(100%);
    right: -30px;
    display: flex;


    a {
        color: #fff;
        font-size: 9px;
        display: inline-block;
        padding: 10px 5px;
        text-transform: uppercase;

        &.active {
            font-weight: 700;
            color: $main;
        }

        &:hover {
            color: $main;
        }
    }


}

.link__recrutation {
    display: inline-block;
    padding: 15px 20px;
    border: 1px solid $main;
    text-transform: uppercase;
    font-weight: 800;
    background: $main;
    color: #fff;
    font-size: 12px;
    transition: none;
    margin-left: 50px;

    &:hover {
        background: $red;
        border-color: $red;

    }

    @media(max-width:$grid-breakpoints-xxl) {
        padding: 10px;
    }
}

.header {
    .link__reservation {
        margin-left: 50px;
    }
}




.nav {

    margin-right: 70px;



    .nav__linkSvg {
        padding-left: 46px;

        svg {
            position: absolute;
            top: 5px;
            ;
            left: 0;
        }

    }

    ul {
        li {
            position: relative;

            a {
                padding: 5px 20px;
                color: #fff;
                font-size: 12px;
                text-transform: uppercase;
                display: inline-block;
                white-space: nowrap;
                font-weight: 900;
                position: relative;
                width: 100%;
                outline: none;
            }

        }

        ul a {
            white-space: normal;
        }
    }

    @media(min-width: 1200px) {
        >ul>li>a {
            font-size: 14px;
        }
    }
}

@media(hover:hover) {
    .nav ul li a {
        &:hover {
            color: $main;
        }

        &:focus {

            text-decoration: underline;
        }
    }

    .menu-is-open {
        .nav ul li a {
            &:hover {
                color: #000;
            }
        }
    }

}

.menu-is-open,
.menu-open {
    .header__logo {
        color: #000;
        fill: #000;
    }

    .header {
        background: $main;
    }

    .header__lang {
        display: none;
    }

    .link__recrutation {
        background: #000;
        transition: none;

    }

    .fixed-header {
        .header__logo {
            color: #ffff;
            fill: #fff;
        }

        .link__recrutation {
            background: $main;
        }
    }
}




.nav>ul {
    list-style: none;
    display: flex;

    @media(max-width:$grid-breakpoints-xxl) {
        gap: 10px;

        >li {
            &:not(:last-child) {
                &:after {
                    right: -9px;
                }
            }
        }
    }

    .nav__level-2 {
        display: none;
        position: fixed;
        z-index: -1;
        top: 0;
        width: 100vw;
        left: 0;
        padding-top: 100px;
        padding-bottom: 50px;
        background: $main;

        text-transform: uppercase;


        ul,
        strong {
            max-width: $container-max-width-xxl;
            padding-left: 344px;
            margin-left: auto;
            margin-right: auto;
        }

        span {
            font-weight: 400;
        }

        ul {
            li {
                margin-left: -15px;
            }

        }

        strong {
            color: #000;
            margin-bottom: 15px;
            font-size: 12px;
            display: none;

        }

        a {
            color: #fff;
            line-height: 1.4;
            font-size: 18px;

        }
    }
}



.nav-toggle {
    min-width: 50px;
    width: 50px;
    height: 50px;
    padding: 12px 0 12px 0;
    background: transparent;
    border: none;
    display: block;
    display: none;
    position: relative;
    top: 2px;
    margin-left: 40px;

    small {
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        bottom: 2px;
        right: 0;

    }
}

.nav-toggle span {
    width: 32px;
    height: 2px;
    background: #fff;
    transition: all 0.3s ease-in-out;
    display: block;
    backface-visibility: hidden;
    border-radius: 1px;
    left: 18px;
    position: absolute;


    &.one {
        top: 14px;
    }

    &.two {
        top: 24px;
        width: 20px;
        margin-left: 12px;
    }

    &.three {
        top: 33px;
    }
}

.nav-toggle.open {

    .two {
        display: none;
    }

    .one {
        position: absolute;
        top: 23px;
        left: 18px;
        transform: rotate(45deg);
        margin: 0;
    }

    .three {
        position: absolute;
        top: 23px;
        left: 18px;
        transform: rotate(-45deg);
        margin: 0;
    }
}


@media(max-width:1560px) {
    .header__lang {
        right: 15px;
    }

    .link__recrutation {
        margin-left: 30px;
        margin-right: 20px;
    }
}

@media(max-width:$grid-breakpoints-xxl) {

    .nav {
        margin-right: 20px;

        ul li a {
            padding-left: 8px;
            padding-right: 8px;
        }

        >ul .nav__level-2 ul li {
            margin-left: -8px;
        }
    }

    .link__recrutation {

        margin-left: 10px;
    }

}



@media(max-width:$grid-breakpoints-xl) {

    .header__logo {
        margin-right: 30px;
    }


    .subpage {
        padding-top: 80px;
    }


    .header__wrapper.container {
        max-width: 100%;
        padding-top: 20px;
        height: 80px;
    }

    .subpage {
        .header__wrapper.container {
            padding-top: 0px;
        }

        .header__lang {
            top: 22px;
        }

        .nav-toggle {
            margin-right: 50px;
        }
    }

    .header__lang {
        right: 50px;
        top: 108px;
    }

    .link__recrutation {
        margin-right: 0;
        margin-left: 40px;
    }

    .header__social {
        margin-left: auto;
    }



    .menu__item--has-children {
        &:before {
            content: "";
            position: absolute;
            top: 12px;
            right: 35px;
            display: block;
            width: 15px;
            height: 15px;
            border-left: 4px solid #fff;
            border-bottom: 4px solid #fff;
            transform: rotate(-45deg);
        }

    }

    .nav-toggle {
        display: block;
    }

    .nav {
        position: absolute;
        left: 0;
        top: 78px;
        background: $main;
        width: 100%;
        display: none;
        padding: 20px 0;
        margin-right: 0;

        >ul>li>a {
            font-size: 22px;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;

            &:before {
                top: 18px;
            }
        }

        ul {
            display: block;

            li {
                width: 100%;


                a {
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 70px;
                }

                .nav__linkSvg {
                    padding-left: 80px;

                    svg {
                        left: 30px;
                        top: 13px;
                    }
                }
            }

            .nav__level-2 {
                position: relative;
                z-index: 1;
                padding-top: 10px;
                padding-bottom: 20px;

                ul {
                    padding-left: 60px;
                    padding-right: 20px;

                    a {
                        color: #000;
                        padding: 10px 0;
                    }
                }

                strong {
                    display: none;
                }

            }
        }


    }
}

@media(max-width:$grid-breakpoints-md) {
    .header__social {
        display: none;
    }

    .link__recrutation {
        margin-left: auto;
    }
}

@media(max-width:$grid-breakpoints-sm) {

    .header__logo {
        transform: scale(.6) !important;
        position: relative;
        left: -35px;
        width: 120px;
        margin-right: 0;
    }

    .nav {
        height: calc(100vh - 85px);
        overflow: auto;

        >ul {
            padding-bottom: 50px;
        }
    }

    .menu-is-fixed .nav {
        height: calc(100vh - 60px);
    }

    .nav-toggle {
        margin-left: 15px;

    }

    .subpage {
        .nav-toggle {
            margin-right: 0;

        }

        .header__lang {
            top: 108px;
        }
    }
}


@media(max-width:400px) {
    .nav-toggle {
        margin-left: 5px;
    }
}


.breadcrumbs {

    margin-bottom: 50px;

    ul {
        display: flex;
        flex-wrap: wrap;

        span,
        a {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.4);
            display: inline-block;
            padding: 5px 0;
            line-height: 1;
        }

        a {
            margin-right: 10px;

            &:after {
                margin-left: 10px;
                content: "/";

            }

            &:focus,
            &:hover {
                outline: none;
                color: $main;
            }
        }
    }

    @media(max-width:$grid-breakpoints-md) {
        ul {

            a,
            span {
                font-size: 12px;
            }
        }
    }
}



.page__header {
    min-height: 280px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    position: relative;

    @media(max-width:$container-max-width-sm) {
        min-height: 200px;
    }

    .breadcrumbs,
    .container {
        position: reltive;
        z-index: 10;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        z-index: 1;
        object-fit: cover;
    }


    @media(min-width:$grid-breakpoints-xxl) {
        .container--md {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

}

.page__header--bc {
    padding-top: 30px;

    .article__info {
        margin-top: 0;
        margin-bottom: 20px;
    }

}

.page__header--film {
    padding-top: 30px;

    &:before {
        content: "";
        width: 50%;
        height: 100%;
        background: linear-gradient(to right, rgb(0, 0, 0, ), rgba(0, 0, 0, 0));
        position: absolute;
        z-index: 2;
        display: block;
        top: 0;
        left: 0;

    }

    h1 {
        color: #fff;
        margin-bottom: 5px;
        ;
    }

    .breadcrumbs {

        a,
        span {
            color: rgba(255, 255, 255, 0.4)
        }
    }
}

.header__time {
    color: #fff;
    display: flex;
    font-size: 20px;
    gap: 40px;
}

.page__header--center {
    justify-content: center;
    padding-top: 40px;

    h1 {
        margin-bottom: 0;
    }
}

.page__header--flex {
    display: flex;
    gap: 30px;
    position: relative;
    padding-left: 135px;

    >div {}

    svg {
        position: absolute;
        fill: $main;
        color: #000;
        top: 50%;
        transform: translateY(-50%) scale(0.7);
        left: -20px;
    }

    h1 {
        margin-top: 30px;
        margin-bottom: 0;
    }

    @media(max-width:$grid-breakpoints-sm) {
        padding-left: 0;
        padding-top: 100px;

        svg {
            top: 40px;
            transform: translateY(-50%) scale(0.5);
            left: -30px;
        }

        h1 {
            margin-top: 0;
        }

        .ph__courseName {
            position: absolute;
            left: 90px;
            max-width: calc(100% - 90px);
            top: 40px;
            transform: translateY(-50%);
        }

    }

}

.ph__courseName {
    color: $main;
}


.menu-open {
    @media(max-width:$grid-breakpoints-sm) {
        overflow: hidden;
    }
}