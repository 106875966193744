@import "components/variables";
@import "components/typography.scss";
@import "components/form.scss";
@import "components/footer.scss";
@import "components/header.scss";


.hover-back__txt {
    position: absolute;
    bottom: -100%;
    right: 0;
    padding: 20px 20px 0 20px;
    transition: all 0.5s ease-out;
    text-align: right;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    z-index: 20;
    width: 100%;
    max-width: 260px;

}



.hover-back {
    figure {
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: rgba(0, 175, 154, 0.3);
            z-index: 10;
            opacity: 0;
            transition: all 0.3s ease-out 0.3s;
        }

        &:before {
            position: absolute;
            right: 20px;
            bottom: -100%;
            z-index: 20;
            transition: all 0.3s ease-out;
            content: url("../img/arrow-right-red.svg")
        }
    }

    @media(hover:hover) {

        &:hover {

            figure:after {
                width: 100%;
                opacity: 1;
                transition: all 0.3 ease-out;
            }

            figure:before {
                bottom: 20px;
                transition: all 0.3s ease-out 0.3s;
            }

            .hover-back__txt {
                bottom: 60px;
                transition: all 0.3s ease-out 0.3s;
            }
        }
    }
}

.films__optionsBtn {
    width: 100%;
    margin-bottom: 15px;

    @media(min-width:$grid-breakpoints-sm) {
        display: none;
    }
}

.films__optionsSelects {

    @media(max-width:$grid-breakpoints-sm) {
        display: none;

        .select__wrapper {
            margin-bottom: 10px;
            padding-top: 0;

            .form__select {
                margin-bottom: 10px;
            }
        }

        .page__nav {
            overflow: visible;
            margin-bottom: 10px;

            ul {
                flex-wrap: wrap;
                gap: 5px;
            }

            li {
                width: 100%;
            }
        }

    }

    option {
        background: $bg-grey;
    }


}


.nproduction .row {
    column-gap: 2px;

    @media(max-width:$grid-breakpoints-lg) {
        column-gap: 14px;
    }
}

.nproduction_item {
    max-width: 50%;
    max-width: calc(50% - 7px);
    flex: 0 0 50%;
    margin-bottom: 14px;


    @media(min-width:$grid-breakpoints-md) {
        max-width: calc(25% - 2px);
        flex: 0 0 25%;
    }

    figure {
        padding-top: 140%;

        &:after {
            background: rgba(0, 175, 154, 0.83);
        }
    }

    .hover-back__txt {
        font-size: 24px;
    }
}


.nproduction2 {
    .hover-back__txt {
        font-size: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h3 {
        margin-top: 15px;
    }


    .nproduction_item {

        margin-bottom: 50px;


        @media(max-width:$grid-breakpoints-lg) {
            margin-bottom: 30px;
            max-width: calc(50% - 7px);
            flex: 0 0 50%;

            figure {
                width: 100%;
                padding-top: 70%;

                img {
                    width: 50%;
                    left: 25%;
                }

                background:$main;
            }

            .hover-back__txt {
                width: 50%;
                left: 50%;
                bottom: 20px;
                font-size: 11px;
                font-weight: 400;
                padding: 20px 10px 0 10px;
            }
        }

        @media(max-width:$grid-breakpoints-md) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-left: 0px;
            margin-right: 0px;
        }

        @media(max-width:$grid-breakpoints-sm) {
            h3 {
                margin-top: 10px;
            }

        }
    }

}

.nproduction__link {
    @media(hover:hover) {
        &:hover {
            .btn4 {

                span,
                a {

                    background-size: 15% 2px;
                    background-image: linear-gradient($red, $red);

                }
            }
        }
    }
}


.coach__list {
    list-style: none !important;
    padding-left: 0 !important;

}

.coach__link {
    border-right: 2px solid #fff;

    .img__wrapper {
        width: calc(100% - 2px);
    }


    @media(hover:hover) {

        &:hover {

            figure:after {
                opacity: 1;
            }

            .coach__txt-back {
                bottom: 0;
            }

            .btn4 span {
                background-size: 15% 2px;
                background-image: linear-gradient($main, $main);
            }

            .coach__img-back-wrapper {
                width: 100%;
            }


        }
    }

}

.coach__img-back {
    min-width: 335px;

    @media(max-width:$grid-breakpoints-xxl) {
        min-width: 275px;
    }

    @media(max-width:$grid-breakpoints-xl) {
        min-width: 306px;
    }

    @media(max-width:$grid-breakpoints-lg) {
        min-width: 230px;
    }


}

.coach__img-back-wrapper {
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: all 0.5s ease-out;

    img {
        left: 0;
        top: 0;
        transform: none;
    }
}

.coach__item {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 0px;

    .btn4 {
        margin-top: 10px;
        margin-bottom: 0;
    }

    @media(min-width:$grid-breakpoints-md) {
        max-width: 33.33%;
        flex: 0 0 33.33%;
        margin-bottom: 25px !important;
        ;
    }

    @media(min-width:$grid-breakpoints-xl) {
        max-width: 25%;
        flex: 0 0 25%;
        margin-bottom: 50px !important;
    }

    img {
        transition: opacity 0.5s ease-in-out;
    }

    @media(max-width:$grid-breakpoints-sm) {
        .btn4 {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.course__list {}

.course__item {
    max-width: 50%;
    flex: 0 0 50%;

    .btn4 {
        text-align: center;
        text-transform: uppercase;
    }

    @media(min-width:$grid-breakpoints-md) {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    @media(min-width:$grid-breakpoints-xl) {
        max-width: 25%;
        flex: 0 0 25%;
    }


}

.bg--grey {
    .course__link {
        background: #fff;
    }

    .coach__link {
        border-right-color: $bg-grey;
    }
}

.course__link {
    background: $bg-grey;
    display: block;
    padding: 15px;
    height: 100%;
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        content: "";
        background: $main;
        display: block;
        z-index: 1;
        transition: all 0.5s ease-out;

    }

    .btn4 {
        position: relative;
        z-index: 10;
    }

    .img__wrapper {
        padding-top: 85%;
        z-index: 10;
    }

    .course__svg {
        color: #272727;
        transition: all 0.5s ease-out;
        fill: $main;

        @media(max-width:$grid-breakpoints-xxl) {
            transform: translate(-50%, -50%) scale(0.8);
        }

        @media(max-width:$grid-breakpoints-md) {
            transform: translate(-50%, -50%) scale(0.5);
        }
    }

    @media(hover:hover) {
        &:hover {
            &:after {
                width: 100%;
            }


            .btn4 {
                color: #fff;
            }

            .course__svg {
                color: #272727;
                fill: #fff;
            }

            .btn4 span {
                background-size: 15% 2px;
                background-image: linear-gradient($yellow, $yellow);
            }

        }
    }
}

.mnews__leftWrapper {
    @media(max-width:$grid-breakpoints-lg) {
        .container--half {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
}


.mnews__left {

    background: $main;
    background-image: linear-gradient($main, #000);
    padding: 70px 50px 50px 50px;


    p {
        color: #fff;
        font-size: 22px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-weight: 900;
        line-height: 1.4;
    }

    @media(min-width:$grid-breakpoints-lg) {
        background-image: linear-gradient($main, $main, #000);
        margin: 110px 0 0 0;
        max-width: 570px;
    }
}

.mnews__top {
    text-align: center;

    img {
        max-width: 100%;
        margin-bottom: 50px;
    }
}

.date {
    color: $main;
    font-weight: 700;
}

.tabs__navlist {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}


.tabs__nav-item {}

.tabs__nav-trigger {
    background-color: transparent;
    color: $txt-light;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0;
    border: none;
    display: inline;
    background-position: 0px 93%;
    background-repeat: no-repeat;
    background-size: 0 4px;
    transition: all .3s ease-out;
    background-image: linear-gradient($txt-light, $txt-light);
    font-family: 'Poppins', sans-serif;

    @media(hover:hover) {
        &:hover {
            background-size: 100% 4px;
        }
    }

    &[aria-selected="true"] {
        background-size: 100% 4px;
        color: #000;
        background-image: linear-gradient(#000, #000);
    }
}

.newsM__container {
    padding-top: 50px;
    padding-bottom: 40px;

    @media(min-width:$grid-breakpoints-lg) {
        padding-left: 40px;
    }
}

.newsM__panel {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 1260px;

    @media(min-width: $grid-breakpoints-sm) {
        height: 500px;
    }
}

.tabs__panel {
    position: absolute;
    top: 0;
    width: 100%;
    left: -100%;
    transition: left 0.5s ease-in-out;
    z-index: 2;
    background: $bg-grey;
    height: 100%;

    &[aria-hidden="false"] {
        left: 0;
        z-index: 5;
    }
}

.newsM__item {
    display: block;
    margin-bottom: 35px;
    width: 100%;

    .tagSmall__list {

        li:before {
            background: $main;
        }

        margin-top:-5px;
        margin-bottom:5px;

        a {
            color: $main;

            &:hover {
                color: $red;
            }
        }
    }

    .article__info {
        align-items: flex-end;
        margin-bottom: 5px;
        margin-top: 0;

        a {
            font-size: 14px;
        }
    }

    .img__wrapper {
        padding-top: 50%;
        margin-bottom: 10px;
        cursor: pointer;
    }

    h3 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
        font-size: 21px;

        span,
        a {

            background-image: linear-gradient($main, $main);
            background-position: 0 93%;
            background-repeat: no-repeat;
            background-size: 0 2px;
            transition: all .3s ease-out;
        }


    }

    p {
        line-height: 26px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $txt-dark;
        font-size: 15px;

    }

    @media(min-width: $grid-breakpoints-sm) {
        display: flex;
        align-items: flex-start;
        gap: 15px;

        .img__wrapper {
            min-width: 200px;
            max-width: 200px;
            padding-top: 112px;
        }

        p {
            -webkit-line-clamp: 2;
            font-size: 16px;
        }
    }

    @media(min-width: $grid-breakpoints-lg) {
        gap: 20px;
    }

    @media(hover:hover) {
        &:hover {

            h3 span,
            h3 a {
                background-size: 100% 2px;
            }
        }
    }
}

.newsM__data {
    color: $main;
    font-size: 14px;

    display: block;
}

.newsM__panel {
    .btn2 {
        position: relative;
        left: 190px;

    }
}


.method {
    overflow: hidden;
    position: relative;


    .container {}

    .swiper-slide {
        background: $main;
        height: 460px;
        padding-left: 130px;

        h3,
        h4,
        p {
            color: #fff;
        }

        h4 {
            text-transform: uppercase;
            font-weight: 800;
            margin-bottom: 20px;
        }

        h3 {
            font-size: 50px;
        }

        p {
            margin-bottom: 20px;
            max-width: 460px;
        }
    }

    .swiper-method {
        position: relative;
        z-index: 10;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-right: 50px;

        &:after {
            content: '';
            width: 100vw;
            height: 100%;
            display: block;
            right: 0;
            top: 0;
            background: $main;
            position: absolute;
            z-index: -1;
        }
    }


    @media(min-width:$grid-breakpoints-md) {


        .container {
            padding-top: 100px;
            display: flex;
            align-items: flex-start;
        }

        .swiper-method {
            margin-top: 60px;
            max-width: 75%;
            min-width: 75%;

        }
    }

    @media(min-width:$grid-breakpoints-lg) {
        margin-top: 120px;

        .swiper-method {
            margin-top: 60px;
            max-width: calc(50% + 100px);
            min-width: calc(50% + 100px);

        }
    }

    @media(max-width:$grid-breakpoints-xxl) {
        .swiper-slide {
            padding-left: 80px;

            h3 {
                font-size: 40px;

            }
        }

    }

    @media(max-width:$grid-breakpoints-md) {
        .swiper-method {
            padding-right: 0px;

            &:after {
                right: 50%;
                transform: translateX(50%);
            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {


        .container {
            padding: 0;
        }

        .swiper-method {
            padding: 0;
        }

        .swiper-slide {
            padding: 30px 20px 30px 60px;

            height: 570px;

            h3 {
                font-weight: 400;
            }

            p {
                font-size: 15px;
                line-height: 1.4;
            }
        }
    }
}

.method__title {
    color: rgba(51, 51, 51, 0.2);
    font-size: 206px;
    position: absolute;
    line-height: 1;
    left: 0;
    top: 0;
    text-transform: uppercase;
    // transition:left 3s ease-out;

    @media(max-width:$grid-breakpoints-lg) {
        display: none;
    }

    // &.scrolled{
    //     left:130px;
    // }
}

.method__img {
    position: relative;
    left: -100px;

    @media(max-width:$grid-breakpoints-md) {
        width: 100%;
        left: 0;
    }

}

.swiper-method .swiper-pagination {
    top: 0;
    height: 400px;
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $main;

    .swiper-pagination-bullet {
        display: block;
        height: 100px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    @media(max-width:$grid-breakpoints-xxl) {
        width: 80px;
    }

    @media(max-width:$grid-breakpoints-sm) {

        width: 60px;
        height: 570px;
        top: 0px;

        .swiper-pagination-bullet {
            background: rgba(255, 255, 255, 0.1);
            height: 114px;
        }

        .swiper-pagination-bullet-active {
            background: #000;
            color: #fff;
        }
    }
}


.newsletter {
    padding-top: 50px;
    padding-bottom: 40px;
    background: $main;
    color: #fff;

    h2 {
        margin-bottom: 0;
        padding-right: 20px;

        @media(min-width:$grid-breakpoints-lg) {
            padding-right: 80px;
        }
    }

    // input,
    // ::placeholder {
    //     font-weight: 500;
    // }
}

.newsletter__wrapper {
    display: flex;
    flex-wrap: wrap;
    text-align: flex-end;
    gap: 30px;
}

.newsletter__form {
    max-width: 100%;
    width: 500px;
    border-bottom: 1px solid #F4F5F0;
    justify-content: space-between;
    display: flex;

    input {
        background: none;
        color: #fff;
        border: none;
        padding: 10px 0;
        width: 300px;
        max-width: 85%;
        font-size: 1.125rem;

        &::placeholder {
            color: #fff;
        }

        @media(min-width:$grid-breakpoints-lg) {
            width: 400px;
        }
    }

    .btn-form {
        background: none;
        border: none;
        width: 50px;
        height: 50px;
        text-align: right;
        padding: 0;
        position: relative;

        &:after {
            position: absolute;
            top: 20px;
            right: 0;
            content: url("../img/arrow-right-w.svg");
        }
    }
}

.form--newsletter-out {

    position: relative;
    max-width: 400px;

    .form__input {
        margin-bottom: 0;
        width: 100%;
        height: 50px;

        input:focus {
            outline-offset: 0;
        }
    }

    @media(min-width:$grid-breakpoints-sm) {
        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 19px 25px 20px 25px;
            height: 51px;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        button {
            width: 100%;
        }
    }
}



.bigP {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    background: #000;

    h1,
    h2 {

        max-width: 800px;
    }

    .bigP__titleContainer {
        margin-bottom: 120px;
    }

    @media(min-width:$container-max-width-xl) {
        min-height: 900px;

        .bigP__titleContainer {
            margin-bottom: 150px;

        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        // height: 100vh;
        // min-height: 550px;

        h1,
        h2 {

            font-weight: 600;
        }

        .bigP__titleContainer {
            margin-bottom: 50px;
        }
    }

    .bigP__title {
        opacity: 0;
        z-index: 5;
        transition: none;
        position: absolute;
        transition: none;
        top: 0;
        left: 0;

        &.active {

            z-index: 10;
            animation: titleBIg 10s linear;
        }
    }

}

.bigP__titleContainer {
    position: relative;
    min-height: 120px;

}

@keyframes titleBIg {
    0% {
        opacity: 0
    }

    30% {
        opacity: 0;
    }

    50% {
        opacity: 1
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0
    }
}

.bigP__wrapperImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: linear-gradient(#000, rgba(0, 0, 0, 0), #000);
        z-index: 12;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 2s ease-in;
        opacity: 0;
        z-index: 2;

        &.active {
            opacity: 0.85;
            z-index: 10;
            transition: opacity 5s ease-in-out;
        }

    }

}

.bigP__logo {
    color: #fff;
    fill: $red;
    margin-bottom: 80px;
}

.bigP__wrapperTxt {
    position: relative;
    z-index: 15;
    color: #fff;
    padding-top: 250px;
    padding-bottom: 220px;



    @media(max-width:$grid-breakpoints-sm) {
        padding-bottom: 60px;
        padding-top: 200px;
    }
}

.bigP__list {
    display: flex;
    gap: 20px;
    max-width: 1100px;
    justify-content: space-between;
    align-items: flex-end;


    @media(max-width:$grid-breakpoints-lg) {
        flex-wrap: wrap;
        row-gap: 40px;
        column-gap: 10px;

        li {
            max-width: calc(50% - 5px);
            flex: 0 0 50%;

            a {
                font-size: 16px;

            }

        }
    }

    @media(max-width:$grid-breakpoints-md) {
        li a {
            font-size: 14px;
            display: inline-block;
            line-height: 1.4;
            font-weight: 600;
        }
    }


}

.bigP__red.bigP {
    min-height: auto;
    border-top: 1px solid #fff;

    h1,
    h2 {
        max-width: 800px;
    }


    .bigP__list {
        max-width: 800px;
    }

    .bigP__wrapperTxt {

        padding-bottom: 120px;
    }

    @media(max-width:$grid-breakpoints-sm) {
        .bigP__logo {
            margin-bottom: 50px;
        }

        .bigP__wrapperTxt {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }
}


.swiper-opinion {
    height: 550px;
    background: $txt;

    .swiper-button-prev {
        right: calc(50% + 10px);
        bottom: 65px;
    }

    .swiper-button-next {
        right: calc(50% - 70px);
        bottom: 65px;
    }

    .swiper-pagination {
        left: calc(50% - 50px);
        color: #fff;
        transform: rotate(-90deg);
        font-size: 9px;
        bottom: 150px;

        span {

            &:after {
                content: none;
            }
        }
    }

    .swiper-slide-active {
        .opinion__item {
            &:first-child {
                .container--half {
                    left: 0;
                }
            }

            &:nth-child(2) {
                .container--half {
                    right: 0;
                }
            }

            .opinion__imgWrapper {
                width: 100%;
            }

            .container--half {
                opacity: 1;
            }
        }

    }
}

.opinion__wrapper {
    display: flex;
    position: relative;

    @media(min-width:$grid-breakpoints-md) {
        &:after {
            content: "";
            display: block;
            border-right: 1px solid #fff;
            position: absolute;
            height: 100%;
            left: 50%;
            top: 0;
            z-index: 80;

        }
    }

    .container--half {
        position: relative;
        display: flex;
        height: 100%;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 80px;
        opacity: 0;
        transition: left 0.5s ease-out 0.5s, right 0.5s ease-out 0.5s, opacity 0.5s ease-out 0.5s;
        z-index: 30;
    }
}

.opinion__item {
    max-width: 50%;
    flex: 0 0 50%;
    position: relative;
    height: 550px;
    color: #fff;

    &:after {
        content: "";
        display: block;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

    }

    span,
    strong {
        display: block;
        text-align: right;
    }



    &:first-child {

        .container--half {
            margin-right: 0;
            padding-right: 100px;
            left: -100px;
        }

        .opinion__imgWrapper {
            left: initial;
            right: 0;
        }

        img {
            left: initial;
            right: 0;
        }
    }

    &:nth-child(2) {
        .container--half {
            margin-left: 0;
            padding-left: 100px;
            right: -100px;

        }
    }
}

.opinion__imgWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    overflow: hidden;
    transition: width 0.5s ease-out;



    img {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        height: 100%;
        width: 50vw;

    }
}


.opinion__txt {
    margin-bottom: 20px;
    position: relative;
    line-height: 1.5;

    &:before {
        content: url("../img/quote.svg");
        position: absolute;
        left: 0;
        top: -40px;
    }


}

// @media (max-width:$grid-breakpoints-xxl) {
//     .opinion__imgWrapper img {
//         width:790px
//     }
// }
// @media (max-width:$grid-breakpoints-xl) {
//     .opinion__imgWrapper img {
//         width:600px
//     }
// }
// @media (max-width:$grid-breakpoints-lg) {
//     .opinion__imgWrapper img {
//         width:500px
//     }
// }
@media (max-width:$grid-breakpoints-md) {

    .swiper-opinion {
        height: 850px;
    }

    .opinion__wrapper {
        display: block;
    }

    .opinion__imgWrapper img {
        width: 100vw;
    }

    .opinion__item {
        max-width: 100%;
        flex: 0 0 100%;
        height: 390px;

        &:first-child {
            margin-bottom: 70px;
        }

        .container--half {
            padding-left: 30px !important;
            padding-right: 30px !important;
            padding-bottom: 30px;
        }

        span,
        strong {
            font-size: 12px;
        }
    }

    .swiper-opinion {

        .swiper-button-prev {
            bottom: 395px;
            right: initial;
            left: 20px;
        }

        .swiper-button-next {
            bottom: 395px;
            left: initial;
            right: 20px;
        }

        .swiper-pagination {
            transform: translateX(-50%);
            bottom: 415px;
            left: 50%;
            font-size: 14px;
        }

    }
}


.intro__imgWrapper {
    display: flex;

    img {
        object-fit: cover;

        @media(max-width:$grid-breakpoints-lg) {
            width: 100%;
            max-height: 600px;
        }
    }
}

.intro__txt {
    padding-top: 90px;
    padding-bottom: 40px;

    p {
        margin-bottom: 20px;
    }

    @media(max-width:$grid-breakpoints-sm) {
        padding-top: 50px;
    }
}

.course2__list {
    li {
        padding-bottom: 15px;
        padding-top: 20px;
        border-bottom: 1px solid $line;

        @media(max-width:$grid-breakpoints-sm) {
            padding-bottom: 25px;
            padding-top: 30px;
        }

    }

}


.course2__txt {

    width: 100%;

    h3 {
        margin-bottom: 10px;
        font-size: 21px;
    }


    p {
        margin-bottom: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.course2__list {
    list-style: none !important;
    padding-left: 0 !important;
}

.course2__link {
    display: flex;
    align-items: center;
    gap: 50px;
    background-image: linear-gradient($bg-grey, $bg-grey);
    background-size: 0 100%;
    background-repeat: no-repeat;

    transition: all .5s ease-out;

    svg {
        fill: $main;
    }


    .img__wrapper {
        flex: 0 0 225px;
        max-width: 225px;
        height: 225px;
        padding-top: 0;

        &:first-of-type {
            background: $bg-grey;
        }
    }

    @media(hover:hover) {
        &:hover {
            background-size: 100% 100%;

            .btn2:after {
                background: $main;
                width: 20%;
            }
        }
    }

    @media(max-width:$grid-breakpoints-xl) {
        gap: 30px
    }


    @media(min-width:$grid-breakpoints-md) and (max-width:$grid-breakpoints-lg) {
        .img__wrapper:nth-of-type(2) {
            display: none;
        }
    }

    @media(max-width:$grid-breakpoints-md) {
        flex-wrap: wrap;
        gap: 0;
        background: none;
        padding-bottom: 10px;

        .course2__txt {
            width: 100%;
            order: 3;
            margin-top: 20px;
        }

        .img__wrapper {
            flex: 0 0 50%;
            max-width: 50%;
            padding-top: 50%;
            height: 0;

            svg {
                transform: translate(-50%, -50%) scale(0.5);
            }
        }

        p {
            -webkit-line-clamp: 5;
        }
    }
}

.green__wrapper {
    background: $main;
    padding-top: 85px;
    padding-bottom: 85px;
    color: #fff;
    font-size: 24px;

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 21px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

}


.select__wrapper {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-top: 10px;

    .form__select {
        margin-top: 0;

        &:after {
            background: $bg-grey;
            ;
        }
    }

    .btn {
        margin-bottom: 25px;
    }
}



.page__nav {
    margin-bottom: 20px;
    overflow: auto;

    input {
        opacity: 0;
        position: absolute;
        z-index: -1000;
        overflow-y: hidden;
        width: 0;
        height: 0;
    }

    label {
        cursor: pointer;
    }



    ul {
        display: flex;
        gap: 0 20px;

        li {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        a,
        button,
        label {
            background-color: transparent;
            font-family: 'Poppins', sans-serif;
            color: $txt-light;
            font-size: 18px;
            font-weight: 700;
            padding: 10px 0;
            border: none;
            display: inline;
            line-height: 1;
            background-position: 0px 93%;
            background-repeat: no-repeat;
            background-size: 0 4px;
            transition: all .3s ease-out;
            background-image: linear-gradient($txt-light, $txt-light);
            vertical-align: bottom;
            line-height: 1;
            align-items: flex-start;


            @media(hover:hover) {
                &:hover {
                    background-size: 100% 4px;
                }
            }

            &[aria-selected="true"],
            &.active {
                background-size: 100% 4px;
                color: #000;
                background-image: linear-gradient(#000, #000);
            }
        }

        @media(max-width:$grid-breakpoints-sm) {
            gap: 15px;

            a,
            button,
            label {
                font-size: 16px;
                white-space: nowrap;
            }
        }

        input:checked+label {
            background-size: 100% 4px;
            color: #000;
            background-image: linear-gradient(#000, #000);
        }
    }
}

.tag__list {
    display: flex;
    margin-top: 25px;
    flex-wrap: wrap;


    li {
        padding-right: 15px;
        padding-left: 15px;
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            top: 50%;
            margin-top: -2px;
            left: -2px;
            position: absolute;
            background: $txt-light;
        }

        a {

            white-space: nowrap;
            padding-top: 5px;
            padding-bottom: 5px;

            @media(min-width:$grid-breakpoints-md) {
                font-size: 20px;
            }

        }
    }
}


.tagSmall__list {
    display: flex;
    flex-wrap: wrap;


    li {
        padding-right: 5px;
        padding-left: 5px;
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 2px;
            border-radius: 50%;
            top: 50%;
            margin-top: -1px;
            left: -1px;
            position: absolute;
            background: $txt-light;
        }

        a {

            white-space: nowrap;
            padding-top: 5px;
            padding-bottom: 5px;
            color: $txt-light;

            @media(hover:hover) {
                &:hover {
                    color: $main
                }
            }

        }
    }
}

.tags {
    color: $txt;
}

.article__autor {
    display: block;
    color: $txt-light;
}

.article__list {
    display: flex;
    padding-top: 70px;
    flex-wrap: wrap;
    gap: 30px;

    padding-bottom: 80px;
}

.article__item {

    margin-bottom: 40px;
    position: relative;
    width: 100%;


    .date {
        white-space: nowrap;
    }

    img {
        cursor: pointer;
    }


    h3 {
        font-size: 40px;
        line-height: 1.2;
        margin-bottom: 20px;
        margin-top: 10px;


        @media(max-width:$grid-breakpoints-xxl) {
            font-size: 36px;
        }

        @media(max-width:$grid-breakpoints-md) {
            font-size: 21px;
        }
    }

    @media(min-width:$grid-breakpoints-lg) {
        flex: 0 0 50%;
        max-width: calc(50% - 15px);
    }
}

.article__info {
    display: flex;
    flex-wraP: wrap;
    gap: 10px 20px;


    @media(max-width:$grid-breakpoints-sm) {
        gap: 5px 20px;
        margin-bottom: 15px;
    }

    a {
        color: $txt-light;

        &:hover {
            color: $main;
        }
    }
}

.article__box {
    .tagSmall__list {
        gap: 10px;
        margin-top: 15px;

        li {
            padding: 0;

            &:before {
                content: none;
            }
        }

        a {
            background: $main;
            color: #fff;
            padding: 3px 15px;
            display: block;

            @media(hover:hover) {
                &:hover {
                    color: #fff;
                    background: $main-light;
                }
            }
        }
    }
}

.article__item--first {
    background: #000;

    .img__wrapper {
        padding-top: 56%;
    }

    .article__box {

        width: 100%;
        padding: 0 20px 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        z-index: 5;
    }

    @media(min-width:$grid-breakpoints-lg) {
        .article__box {
            padding: 30px 50px 100px 50px;
        }
    }

    .article__summary {
        display: none;
    }

    h3 {
        color: #fff;
        font-weight: 400;
    }

    .article__autor {
        color: rgba(255, 255, 255, 0.7);
    }
}


.article__item--second {
    .img__wrapper {
        padding-top: 56%;
        margin-bottom: 30px;

        @media(max-width:$grid-breakpoints-lg) {
            margin-bottom: 15px;
        }
    }

}


.article__item--third {

    margin-bottom: 20px;

    .img__wrapper {
        min-width: 158px;
        max-width: 158px;
        padding-top: 158px;
    }

    display:flex;
    align-items: flex-start;
    column-gap:30px;

    .article__summary {
        display: none;
    }

    h3 {
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .article__info {
        font-size: 14px;
    }


    @media(max-width:$grid-breakpoints-sm) {
        column-gap: 15px;
        align-items: stretch;
        margin-bottom: 0;


        .img__wrapper {
            margin-left: -30px;
            padding-top: 158px;
            max-width: 120px;
            min-width: 120px;
        }


        h3 {
            font-size: 19px;
        }
    }

}

.article__intro {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;

    p {
        margin-bottom: 0;
    }
}

.article__wrapper {
    padding-top: 0px;

    .page__nav {
        position: sticky;
        z-index: 99;

        top: 0px;
        padding-top: 70px;
        padding-bottom: 10px;
        margin-bottom: 0;

    }

    ol,
    ul,
    p {
        margin-bottom: 15px;
        font-size: 18px;
    }

    ul,
    ol {
        list-style: initial;
        padding-left: 30px
    }

    ol {
        list-style: decimal;
    }


    li {
        margin-bottom: 10px;
    }

    @media(min-width:$grid-breakpoints-sm) {

        h2 {
            margin-bottom: 50px;
        }

        h3 {
            margin-bottom: 15px;
        }

        ol,
        ul,
        p {
            margin-bottom: 20px;

        }
    }


    .article__txt {
        a {
            text-decoration: underline;

            @media(hover:hover) {
                &:hover {
                    color: $main;
                }
            }
        }
    }
}



.article--date {
    padding-top: 60px;
    margin-top: -60px;

    >strong {
        font-size: 50px;
        margin-bottom: 40px;
        display: block;

        @media (max-width: 576px) {
            font-size: 34px;
            margin-bottom: 25px;

        }
    }

    .coach__item {
        margin-bottom: 30px !important;
    }
}


.page--small {

    strong {
        font-weight: 500;
    }

    ol,
    ul,
    p {
        font-size: 15px;
        color: $txt-light
    }

    li {
        margin-bottom: 8px;
    }
}


.list--decor {
    list-style: none !important;
    padding-left: 0 !important;
    margin-bottom: 50px !important;

    @media(min-width:$grid-breakpoints-xl) {
        display: flex;

        >ul {
            padding-right: 30px;
            margin-bottom: 0 !important;
            min-width: 30%;
        }
    }

    >ul {
        padding-left: 30px;
        position: relative;
        margin-bottom: 30px !important;


        &:after {
            content: "";
            background: $main;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 9px;
            background: $main;

        }

        p {
            margin-bottom: 0;
        }
    }

    ul {
        list-style: none;

        li {
            margin-bottom: 0;
        }

        h4 {
            font-size: 21px;
            font-weight: 900;
            margin-bottom: 10px;

            @media(min-width:$grid-breakpoints-sm) {
                font-size: 24px;
            }

            @media(min-width:$grid-breakpoints-xxl) {
                white-space: nowrap;
            }
        }
    }
}


.readmore__list {
    list-style: none !important;
    padding-left: 0 !important;

    li {
        margin-bottom: 0;
    }


}

.readmore__link {
    display: flex;
    align-items:flex-start;
    gap: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $line;
    position: relative;

    .img__wrapper {
        max-width: 140px;
        flex: 0 0 140px;
        padding-top: 78px;
        img{
            object-fit:contain;
        }
    }

    h3 {
        font-size: 21px;
        font-weight: 400;
    }

    p {
        margin-bottom: 0;
        font-size: 18px;
        color: $txt-dark;
    }

    @media(hover:hover) {
        &:hover {
            h3 span {
                background-size: 100% 4px;
            }
        }
    }


    @media(max-width:$grid-breakpoints-md) {
        gap: 15px;
        align-items: flex-start;

        .img__wrapper {
            padding-top:67px;
            max-width: 120px;
            min-width: 120px;
        }

        p {
            font-size: 16px;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        flex-wrap: wrap;
        padding-top: 25px;
        padding-bottom: 20px;

        .img__wrapper {
            padding-top: 56%;
            max-width: 100%;
            min-width: 100%;
            ;
        }

    }
}


.partners__list {
    list-style: none !important;
    padding-left: 0 !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px 30px;

    li {
        // flex: 0 0 25%;
        // max-width: calc(25% - 9px);


        img {
            max-width: 100%;
           height: 40px;
            width:auto;
            object-fit: contain;
        }

        // a {
        //     display: block;
        //     text-align: left;
        // }
    }

    @media(max-width:$grid-breakpoints-sm) {
        gap: 20px;

        li {
            flex: 0 0 50%;
            max-width: calc(50% - 10px);
            text-align: center;

        }
    }

    @media(min-width:$grid-breakpoints-xl) {
        padding-right: 200px;
    }


}




.team__list {
    list-style: none !important;
    padding-left: 0 !important;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 50px;
}

.team__item {
    flex: 0 0 50%;
    max-width: calc(50% - 25px);
    display: flex;
    gap: 30px;
    padding-right: 30px;

    h5,
    h4 {
        color: #000;
    }

    h4,
    h5,
    a {
        font-size: 18px;
        font-weight: 400;
    }

    h4 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h5 {
        margin-bottom: 10px;
    }

    a {
        color: $main;
    }


    .img__wrapper {
        max-width: 50%;
        min-width: 50%;
        padding-top: 50%;
    }

    @media(max-width:$grid-breakpoints-xxl) {
        .img__wrapper {
            max-width: 40%;
            min-width: 40%;
            padding-top: 40%;
        }
    }

    @media(max-width:$grid-breakpoints-xl) {
        flex: 0 0 100%;
        max-width: 100%;

        .img__wrapper {
            max-width: 30%;
            min-width: 30%;
            padding-top: 30%;
        }
    }

    @media(max-width:$grid-breakpoints-md) {
        gap: 20px;

        h4 {
            margin-bottom: 10px;
            margin-top: 0;

        }

        h5 {
            margin-bottom: 0;
        }

        h5,
        a {
            font-size: 14px;
        }

        flex: 0 0 100%;
        max-width: 100%;

        .img__wrapper {
            max-width: 30%;
            min-width: 30%;
            padding-top: 30%;

        }
    }

    @media(max-width:$grid-breakpoints-md) {
        .img__wrapper {
            margin-left: -30px;
            max-width: 40%;
            min-width: 40%;
            padding-top: 40%;
        }
    }
}



.artpag__list {

    padding-top: 50px;
    padding-bottom: 70px;
    margin-bottom: 50px;
    position: relative;
    gap: 30px;



    @media(min-width:$grid-breakpoints-lg) {
        display: flex;

        li:not(.artpag__last) {
            flex: 0 0 50%;
            max-width: calc(50% - 15px);


        }
    }
}

.artpag__last {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

}

.artpag__link {
    display: block;

    strong {
        display: block;
        margin-bottom: 20px;
        font-size: 24px;
    }

    margin-bottom: 30px;

    .img__wrapper {
        padding-top: 56px;
        max-width: 100px;
        min-width: 100px;
    }

    .date {
        font-size: 12px;
        margin-bottom: 5px;
        display: block;
        font-weight: 400;
    }

    h3 {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 0px;

        span {
            background-position: 0 80%;
        }
    }

    @media(hover:hover) {
        &:hover {
            svg {
                stroke: $main;
            }

            h3 span {
                background-size: 100% 4px;
            }
        }
    }

    @media(min-width:$grid-breakpoints-lg) {

        gap: 30px;

        .date {
            margin-top: -5px;
        }

        .img__wrapper {
            padding-top: 78px;
            max-width: 140px;
            min-width: 140px;
        }


    }

    @media(max-width:$grid-breakpoints-sm) {
        h3 {
            font-size: 16px;
        }

        .artpag__wrapper {
            gap: 10px;
            position: relative;
            flex-wrap: wrap;
        }

        .artpag__txt {
            position: static;
            padding-bottom: 0;
            width: 100%;
        }

        .date {
            top: 8px;
            position: absolute;

        }

        .img__wrapper {
            order: -1;
        }

        svg {
            bottom: initial;
            top: 32px;
        }

        &.artpag__link--left {
            svg {
                left: 120px;
            }

            .date {
                left: 120px;
            }
        }

        &.artpag__link--right {
            svg {
                right: 120px;
            }

            .date {
                right: 120px;
            }

            .artpag__wrapper {
                justify-content: flex-end;
            }
        }
    }
}

.artpag__wrapper {
    display: flex;
    gap: 20px;

}

.artpag__txt {
    padding-bottom: 30px;
    position: relative;
    width: 100%;

    svg {
        stroke: $txt;
        position: absolute;
        transition: all 0.3s ease-out;
        bottom: 10px;
    }

}

.artpag__link--right {
    margin-left: auto;
    text-align: right;

    svg {
        right: 0;
    }
}

.artpag__link--left {
    margin-right: auto;

    svg {
        left: 0;
    }
}


.film__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-left: 9px solid $main;
    padding: 5px 20px;
    gap: 20px;
    margin-bottom: 50px;
    width: 100%;


    strong span {
        font-weight: 400;
        margin-right: 10px;
    }

    @media(max-width:$grid-breakpoints-xxl) {
        flex-wrap: wrap;
        justify-content: flex-start;

        padding: 15px 10px 15px 15px;
    }


}

.film__courses {
    display: flex;
    gap: 30px;
    align-items: center;
    font-weight: 900;
    margin-left: auto;

    @media(max-width:$grid-breakpoints-xxl) {
        margin-left: initial;
    }

    @media(max-width:$grid-breakpoints-md) {
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 10px;
    }

    >div {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }
}

.film__course {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 18px;



    .img__wrapper {
        max-width: 60px;
        min-width: 60px;
        padding-top: 60px;
        margin-right: 10px;

        svg {
            transform: translate(-50%, -50%) scale(0.3);
            fill: $main;
            color: #000;
        }
    }

}


.film__section2 {

    margin-bottom: 40px;

    img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }


    @media(min-width:$grid-breakpoints-md) {
        img {
            max-width: 312px;

        }

        display: flex;

        gap: 30px;
        align-items: flex-start;

        p {
            font-size: 20px;
        }
    }
}

.film__section3 {

    @media(min-width:$grid-breakpoints-xl) {
        display: flex;
        gap: 50px;
    }
}

.film__list {

    list-styLe: none !important;
    padding-left: 0 !important;

    margin-bottom: 40px !important;

    @media(min-width:$grid-breakpoints-md) {
        flex: 0 0 66.66%;
        column-gap: 40px;
        column-count: 2;
    }


    span {
        display: inline-block;
        min-width: 150px;
        max-width: 150px;

    }

    li {
        display: flex;
        font-size: 14px;
        break-inside: avoid-column;
    }

    .js-novisible {
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0;
        transition: max-height 0 ease-out;

    }

    .js-visible {
        max-height: 300px;
        margin-bottom: 10px;
    }

    .link--mainBold {
        border: none;
        padding: 10px 0;
        background: none;

        span {
            min-width: auto;
            max-width: auto;
            border: none;

            &.hide {
                display: none;
            }

        }
    }
}




.film__acc {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-bottom: 30px;
}


.article4__wrapper {
    display: flex;

    flex-wrap: wrap;
    padding-top: 60px;

}

.article4__left {
    width: 100%;





    @media(min-width:$grid-breakpoints-md) {
        .row {
            margin-left: -15px;
            margin-right: -15px;
        }

        .coach__item {
            padding-left: 15px;
            padding-right: 15px;
            max-width: 50%;
            flex: 0 0 50%;
        }
    }

    @media(min-width:$grid-breakpoints-xl) {
        .coach__item {
            max-width: 33.33%;
            flex: 0 0 33.33%;
        }
    }
}

.article4__nav {
    width: 100%;
    font-size: 24px;

    .btn {
        width: 100%;

    }

    a {
        color: $txt-dark;
        line-height: 2;
        width: 100%;
        display: block;


        &:target,
        &.active {
            color: $main;
        }

        @media(hover:hover) {
            &:hover {
                color: $main;
            }
        }
    }
}

.article4__navT {
    display: none;

}



@media(max-width:$grid-breakpoints-md) {
    .article4__nav {
        a {
            text-align: center;


        }

        ul {
            padding-top: 20px;
            background: #fff;
            display: none;
            max-height: 240px;
            overflow-y: auto;
        }
    }

    .article4__left {
        padding-top: 30px;
    }
}


@media(max-width:$grid-breakpoints-sm) {

    .coach__list--2.row {
        margin-left: -30px;
        margin-right: -30px;
    }
}

@media(min-width:$grid-breakpoints-md) {
    .article4__wrapper {
        flex-wrap: nowrap;
    }

    .article4__nav {
        max-width: 230px;
        flex: 0 0 230px;

        padding-left: 30px;

        .btn {
            display: none;
        }

        .article4__navT {
            display: block;
        }
    }

    .article4__left {
        order: -1;
    }
}


@media(min-width:$grid-breakpoints-xl) {
    .article4__nav {

        padding-left: 80px;

    }
}

.article4__sticky {
    position: sticky;
    top: 60px;
}




.laureate__item {
    margin-bottom: 0 !important;

    img {
        max-width: 100%;
    }

    h2 {
        font-weight: 700;
        margin-bottom: 15px;
        font-size: 21px;
        line-height: 1.2;
    }

    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
    }

    li ol {
        list-style: decimal;
        padding-left: 20px;

        li {
            padding-left: 10px;
        }
    }
}

.laureate__list {
    padding-left: 0 !important;
    list-style: none !important;
}

@media(max-width:$grid-breakpoints-lg) {
    .laureate__item {
        padding-bottom: 50px;
    }

    .laureate__left {
        margin-bottom: 10px;
    }
}

@media(min-width:$grid-breakpoints-lg) {
    .laureate__item {
        margin-left: -30px;
        margin-right: -30px;
        display: flex;

        position: relative;

        &:nth-child(even) {
            img {
                display: block;
                margin-left: auto;
            }
        }

        h2 {
            font-size: 36px;
        }

        &:before {
            content: "";
            width: 1px;
            background: $input-border;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
        }

        &:after {
            content: "";
            width: 11px;
            border: 1px solid $input-border;
            background: #fff;
            height: 11px;
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translateX(-5px);
        }

        &:nth-child(odd) {
            .laureate__right {
                order: -1;
            }
        }


    }



    .laureate__left,
    .laureate__right {
        max-width: 50%;
        min-width: 50%;
        padding-left: 30px;
        padding-right: 30px;

        padding-bottom: 50px;


    }
}

@media(min-width:$grid-breakpoints-xl) {
    .laureate__item {
        margin-left: -50px;
        margin-right: -50px;
    }

    .laureate__left,
    .laureate__right {
        padding-left: 50px;
        padding-right: 50px;
    }
}


.accordion__panel button:not(.accordion__btn2) {
    background: transparent;
    width: 100%;
    border: none;


}

.accordion__panel button{
    .hide{
        display:inline-block;
    }
    .open{
        display:none;
    }
}
.accordion__panel button.active{
    .open{
        display:inline-block;
    }
    .hide{
        display:none;
    }
}


.accordion__content {
    overflow: hidden;
    margin-bottom: 30px;
    transition: height 0.3s ease-out;

}

.img__wyswig {
    position: relative;
    width: fit-content;

    img {
        max-width: 100%;
        margin-bottom: 0;
        vertical-align: middle;
    }

    span {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 3px 10px;
        max-width: 100%;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 12px;
        font-style: italic;
        display: inline-block;
        text-align: right;

    }
}