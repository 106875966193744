@import "variables";
@import "fonts";


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,

font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

em {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

html {
    scroll-behavior: smooth;
}

body {
    line-height: 1.6;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    color: $txt;
    font-style: normal;
    scroll-behavior: smooth;

}

iframe,
video {
    max-width: 100%;

}




.film__wrapper {
    display: block;
    width: 100%;
    padding-top: 56.25%;
    height: 0;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    iframe,
    video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}


.info {
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    border: 1px solid $main;
    padding: 15px 20px;
    display: block;
    font-size: 18px;


    @media(min-width:$grid-breakpoints-sm) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        padding: 30px 40px
    }
}

.info--false {
    color: $red;
    border-color: $red;
}

.info--true {
    color: $main;
    border-color: $main;
}

blockquote {
    color: $main;
}

blockquote,
.title--decor {
    font-weight: 900;
    font-size: 24px;
    padding-left: 25px;
    position: relative;
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;

    &:after {
        content: "";
        background: $main;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 9px;
        background: $main;


    }

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 21px;
    }
}

.title--decor {
    text-transform: uppercase;

    margin-bottom: 30px !important;
}


.main {
    color: $main !important;
}

strong {
    font-weight: 900;
}

.font-weight-bold {
    font-weight: 900 !important;
}

.bold {
    font-weight: 900 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font--12 {
    font-size: 12px !important;
}

.font--18 {
    font-size: 18px !important;
}

.font--20 {
    font-size: 20px !important;
}

.font--24 {
    font-size: 24px !important;

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 21px !important;
    }
}

.font--30 {
    font-size: 30px !important;
    margin-bottom: 30px !important;

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 21px !important;
        margin-bottom: 20px !important;
    }
}

.txt-center {
    text-align: center !important;
}


input:not([type="date"], [type="number"]),
textarea,
button {
    -webkit-appearance: none;
    border-radius: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

button {
    padding: 0;
}

/* remember to define focus styles! */
:focus {
    outline: none;
}

:focus-visible {
    outline: 2px solid $main;
    outline-offset: 2px;
}

a,
button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

a {
    color: $txt;
    text-decoration: none;
}



*,
*:before,
*:after {
    box-sizing: border-box;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: separate;
    border-spacing: 0;
    color: $txt-dark;
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
}



h1,
h2,
h3 {
    line-height: 1.2;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;

    a {
        color: inherit;
    }
}

h1,
.h1 {
    font-size: 50px;
    margin-bottom: 30px;


}

h2 {
    font-size: 50px;
    margin-bottom: 40px;

}

h3 {
    font-size: 18px;
    margin-bottom: 10px;

}


h4,
h5,
h6 {
    font-size: 18px;
    font-weight: 900;
}



@media(max-width:$grid-breakpoints-lg) {

    h1,
    .h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 40px;
    }
}

@media(max-width:$grid-breakpoints-sm) {

    h1,
    .h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 34px;
        margin-bottom: 25px;
    }

    h3,
    h4,
    h5,
    h6 {

        font-size: 16px;
    }

}



p,
ul,
ol {
    line-height: 1.6;
}

ul,
ol {
    list-style: none;
    padding-left: 0;
}

.page__txt {

    h2 {
        font-size: 24px;
        margin-top: 50px;
        position: relative;
        padding-left: 25px;


        &:after {
            content: "";
            background: $main;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 9px;
            background: $main;

        }
    }

    blockquote {
        margin-top: 40px;
    }

    img {
        max-width: 100%;
        height: auto;
        margin-top: 15px;
        margin-bottom: 20px;
    }


    ul,
    ol {
        list-style: initial;
        padding-left: 30px
    }

    ol {
        list-style: decimal;
    }

    ol,
    ul,
    p {
        margin-bottom: 20px;
        font-size: 18px;
    }

    li {
        margin-bottom: 5px;
    }

    a {
        text-decoration: underline 2px;

        @media(hover:hover) {
            &:hover {
                color: $main;
            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {

        h2,
        blockquote {
            font-size: 21px;
        }

        ol,
        ul,
        p {
            margin-bottom: 15px;
            font-size: 16px;
        }

    }
}


a,
span,
strong {
    line-height: inherit;
}

.link--mainBold {
    font-weight: 900;
    color: $main;

    &:hover {
        color: $red;
    }
}


.link--main {
    text-decoration: underline;
    color: $main !important;

    &:hover {
        color: $red !important;
    }
}

.link--underline {
    text-decoration: underline;
    text-decoration-thickness: 4px;
    color: $txt;
    font-weight: 900;
    font-size: 18px;

    &:hover {
        color: $main;
    }
}

.link--underlineW {
    color: #fff;

    &:hover {
        color: $main;
    }
}

.link--dark {
    color: $txt;

    @media(hover:hover) {
        &:hover {
            color: $main;
        }
    }
}

.overflow-hidden {
    overflow: hidden;
}


.section {
    padding-top: 50px;
    padding-bottom: 40px;
  

    @media (min-width: $grid-breakpoints-lg) {
        padding-top: 90px;
        padding-bottom: 70px;
    }
}


.section--article {
    margin-top: -80px;
    padding-top: 120px;
    padding-bottom: 60px;  
    @media (max-width: $grid-breakpoints-lg) {
        padding-top: 110px;
        padding-bottom: 40px;
    }
}



.section--50 {
    padding-top: 50px;
    padding-bottom: 40px;
}

.section--20 {
    padding-top: 20px;
    padding-bottom: 30px;
}

.section--30 {
    padding-top: 30px;
    padding-bottom: 30px;
}




.fluid__wrapper {
    display: flex;
    flex-wrap: wrap;
}

.fluid--50 {
    max-width: 100%;
    flex: 0 0 100%;

    @media(min-width:$grid-breakpoints-lg) {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

.w-100 {
    width: 100% !important;
}

.container {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $container-max-width-sm;

    @media (min-width: $grid-breakpoints-md) {
        max-width: $container-max-width-md;
    }

    @media (min-width: $grid-breakpoints-lg) {
        max-width: $container-max-width-lg;

        &.container--sm {
            max-width: 930px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media (min-width: $grid-breakpoints-xl) {
        max-width: $container-max-width-xl;

        &.container--md {
            max-width: 1140px;
        }

        .container--md {
            max-width: 1140px;
            margin-left: auto;
            margin-right: auto;
        }

    }

    @media (min-width: $grid-breakpoints-xxl) {
        max-width: $container-max-width-xxl;

    }

}

.container--mobile-0 {

    @media(max-width:$grid-breakpoints-sm) {
        padding-left: 0px;
        padding-right: 0px;
        max-width: 100%;

        h2 {
            padding-left: 30px;
            padding-right: 30px;
        }

        >p {
            padding-left: 30px;
            padding-right: 30px;
        }
    }




}

.container--half {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $container-max-width-sm;

    @media (min-width: $grid-breakpoints-md) {
        max-width: $container-max-width-md;
    }

    @media (min-width: $grid-breakpoints-lg) {
        max-width: calc($container-max-width-lg / 2);
    }

    @media (min-width: $grid-breakpoints-xl) {
        max-width: calc($container-max-width-xl / 2);
    }

    @media (min-width: $grid-breakpoints-xxl) {
        max-width: calc($container-max-width-xxl / 2);
    }
}

.container--half-lg {
    @media (max-width: $grid-breakpoints-lg) {
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;

        img {
            width: 100%;
            height: auto;
        }
    }

}


.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;

    @media(max-width:$grid-breakpoints-sm) {
        margin-left: -7px;
        margin-right: -7px;
    }

    &.row--0 {
        margin-left: 0px;
        margin-right: 0px;
    }

}

[class*="col-"] {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
    width: 100%;

    @media(max-width:$grid-breakpoints-sm) {
        padding-left: 7px;
        padding-right: 7px;
        margin-bottom: 14px;
    }
}


.row--20 {
    margin-left: -10px;
    margin-right: -10px;

    [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;

        @media(max-width:$grid-breakpoints-sm) {
            padding-left: 7px;
            padding-right: 7px;
            margin-bottom: 14px;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        margin-left: -7px;
        margin-right: -7px;
    }
}




@media (min-width: $grid-breakpoints-md) {

    .col-md-50,
    .col-md-6 {
        width: 50%;
        flex: 0 0 50%;
    }

    .col-md-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    .col-md-5 {
        max-width: 41.67%;
        flex: 0 0 41.67%;
    }

    .col-md-7 {
        max-width: 58.33%;
        flex: 0 0 58.33%;
    }

    .col-md-8 {
        max-width: 66.67%;
        flex: 0 0 66.67%;
    }
}

@media (min-width: $grid-breakpoints-lg) {
    .col-lg-50 {
        width: 50%;
        flex: 0 0 50%;
    }

    .col-lg-6 {
        max-width: 50%;
        flex: 50%;
    }
}

.header__fixed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.bg--grey {
    background: $bg-grey;
}


.img--fluid {
    max-width: 100%;
    height: auto;
}

.box--center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.nowrap {
    white-space: nowrap !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

@media (min-width: $grid-breakpoints-lg) {
    .ml-0-lg {
        margin-left: 0 !important;
    }

    .mr-0-lg {
        margin-right: 0 !important;
    }

    .pl-0-lg {
        padding-left: 0px;
    }

    .pl-60-lg {
        padding-left: 60px;
    }

    .pr-60-lg {
        padding-right: 60px;
    }

    .pt-60-lg {
        padding-top: 60px;
    }
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pt-30 {
    padding-top: 30px !important;
}


.mb-0 {
    margin-bottom: 0px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-100 {
    margin-top: 100px !important;

    @media(max-width:$grid-breakpoints-md) {
        margin-top: 50px !important;
    }
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}




.btn {
    background: $main;
    color: #fff;
    text-transform: uppercase;
    padding: 15px 30px;
    font-size: 12px;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;

    @media(hover:hover) {
        &:hover {
            background: $yellow;
        }
    }
}

.btn--white {
    background: #fff;
    color: $main;
    border-color: $main;

    @media(hover:hover) {
        &:hover {
            background: $main;
            color: #fff;
        }
    }
}


.btn--arrow {
    padding-right: 50px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        right: 20px;
        top: 13px;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #fff;
    }
}

.btn2 {
    background: transparent;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: $txt;
    position: relative;
    padding: 10px 0;

    &:after {
        content: "";
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
        bottom: 5px;
        left: 0;
        background: $txt;
        transition: all 0.3s ease-in-out;
    }

    @media(hover:hover) {
        &:hover {
            &:after {
                width: 20%;
                background: $yellow;
            }
        }
    }
}

.btn2--black {

    color: #000;

    &:after {
        background: #000;
    }

    @media(hover:hover) {
        &:hover {
            &:after {
                background: $yellow;
            }
        }
    }
}

.btn2--white {

    color: #fff;
    line-height: 1.4;

    &:after {
        background: #fff;
        height: 2px;
    }

    @media(hover:hover) {
        &:hover {
            &:after {
                background: $main;
            }
        }
    }
}


.btn3 {
    background: transparent;
    font-size: 18px;
    font-weight: 300;
    color: $txt;
    position: relative;
    padding: 10px 0px 10px 0;
    display: inline-block;

    &:after {
        content: "";
        width: 0;
        height: 2px;
        display: block;
        position: absolute;
        bottom: 3;
        left: 0;
        background: $txt;
        transition: all 0.3s ease-in-out;
    }

    &.active {
        &:after {
            width: 100%;
        }
    }

    @media(hover:hover) {
        &:hover {
            color: $txt;

            &:after {
                width: 100%;

            }
        }
    }
}

.page__header {
    .tagSmall__list {
        li {
            padding-right: 15px;
            padding-left: 15px;

            &:before {
                width: 4px;
                height: 4px;
                top: 50%;
                margin-top: -2px;
                left: -2px;
            }
        }

        a {
            @extend .btn3;
        }
    }
}

.btn4 {
    font-size: 18px;
    text-align: left;
    font-weight: 900;

    span,
    a {
        background-image: linear-gradient(#000, #000);
        padding-bottom: 5px;
        background-position: 0px 93%;
        background-repeat: no-repeat;
        background-size: 100% 2px;
        transition: all .5s ease-out;
        line-height: 1.8;
    }


    @media(hover:hover) {
        &:hover {

            span,
            a {

                background-size: 15% 2px;
                background-image: linear-gradient($main, $main);
            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 16px;

        span {
            background-position: 0px 83%;
        }
    }

}

.btn5 {

    span,
    a {

        background-image: linear-gradient($main, $main);
        padding-bottom: 5px;
        background-position: 0px 90%;
        background-repeat: no-repeat;
        background-size: 0 4px;
        transition: all .3s ease-out;
    }


    @media(hover:hover) {
        &:hover {

            span,
            a {

                background-size: 100% 4px;

            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 16px;

        span {
            background-position: 0px 83%;
        }
    }

}

.img__wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    height: 0;

    img,
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.pagination {
    border-top: 1px solid $line;
    padding-top: 60px;
    padding-bottom: 60px;

    ul {
        margin-left: auto;
        margin-right: auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 100%;
        display: flex;
        gap: 8px;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        padding-left: 50px;
        padding-right: 50px;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border: 1px solid $main;
            font-weight: 900;
            color: $main;
            font-size: 18px;

            &.p-more {
                border: none;
            }




            svg {
                stroke: #000;
                transition: all 0.3s ease-in-out;
            }


            @media(hover:hover) {
                &:hover {
                    border-color: #000;
                    color: #000;

                    svg {
                        stroke: $main;
                    }
                }
            }
        }

        .active {
            a {
                color: #000;
                border: none;
            }
        }


        .prev,
        .next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            a {
                border: none;
            }

            &.disabled {
                opacity: 0;
            }


        }

        .prev {

            left: 0;
        }

        .next {

            right: 0;
        }

        @media(max-width:$grid-breakpoints-lg) {
            gap: 5px;
            padding-left: 40px;
            padding-right: 40px;

            a {
                width: 34px;
                height: 34px;
            }
        }
    }
}


.gallery__list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-left: -30px;
    margin-right: -30px;
    list-style: none !important;
    padding-left: 0 !important;

    .gallery__link {
        padding-top: 60%;
    }

    li {
        width: 100%;
        max-width: calc(50% - 2px);
        flex: 0 0 50%;
        margin-bottom: 0 !important;
    }

    @media(min-width:$grid-breakpoints-sm) {
        margin-left: 0px;
        margin-right: 0px;

        li {
            width: 100%;
            max-width: calc(25% - 3px);
            flex: 0 0 25%;
        }
    }

    @media(min-width:$grid-breakpoints-xl) {
        gap: 16px;

        li {
            width: 100%;
            max-width: calc(25% - 12px);
        }
    }
}

.gallery__list--1 {
    display: flex;
    flex-wrap: wrap;
    list-style: none !important;
    padding-left: 0 !important;
    margin-left: 0px;
    margin-right: 0px;
    gap: 0;

    li {
        width: 100%;
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 0 !important;
    }

    @media(min-width:$grid-breakpoints-sm) {

        li {
            width: 100%;
            max-width: 25%;
            flex: 0 0 25%;
            border-bottom: 1px solid $bg-grey;
            border-right: 1px solid $bg-grey;

            &:nth-child(3n+1) {
                border-right: 0;
            }

            &:first-child {
                border-right: 1px solid $bg-grey;
            }
        }

    }

}

.gallery__link {
    display: block;
    padding-top: 56%;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: rgba(0, 175, 154, 0.5);
        z-index: 10;
        transition: all 0.3s ease-out;
    }

    &:before {
        content: url("../img/btn-search-white.svg");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.5);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease-out 0s;
    }

    @media(hover:hover) {
        &:hover {
            &:after {
                width: 100%;
            }

            &:before {
                z-index: 12;
                opacity: 1;
                transition: opacity 0.3s ease-out;
            }
        }
    }

}


.gallery__embed {
    width: 100%;
    padding-top: 60%;
    position: relative;
    overflow: hidden;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
    }
}



.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 120;
    padding: 30px;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.3s ease;

    &.open {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 21;
        background-color: rgba(0, 0, 0, 0.7);
    }

    &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        border: none;
        outline: none;
        background: none;
        width: 50px;
        height: 50px;
        display: inline-block;
        color: $txt;

        &:after {
            content: "+";

            transform: rotate(45deg);
            font-size: 54px;
            position: absolute;
            top: -8px;
            left: 10px;
            transition: all 0.3s ease-out;

        }

        &:hover {
            color: $red;
        }
    }

    &__container {
        position: relative;
        z-index: 22;
        width: 700px;
        max-width: 96%;
        max-height: 80%;
        overflow: auto;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        padding: 50px;
        background-color: #fff;



    }

    &__title {
        color: $txt;
        font-size: 24px;
        padding-right: 20px;
        margin-bottom: 30px;
        text-align: left;
    }

    .form__input input {
        border: 1px solid $line;
    }

    .form__checkbox label:before {
        border-color: $line;
    }

    @media(max-width:$grid-breakpoints-sm) {
        &__container {
            padding: 20px;
        }

        &__close {
            top: 10px;
            right: 10px;
        }
    }

}