input,
textarea,
select {

    border: 1px solid $input-border;
    line-height: 1.6;
    padding: 10px 15px;
    color: $input;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;

}

::placeholder {
    color: $placeholder;
    font-weight: 300;
}

.disabled {
    color: $placeholder;
}

@keyframes selectAnimation {
    0% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}


.form__select {
    border: 1px solid $input-border;
    padding: 0;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 25px;
    z-index: 10;
    min-width: 150px;

    label {
        color: $placeholder;
        font-size: 16px;
        position: absolute;
        left: 20px;
        bottom: 13px;
        padding: 0;
        font-weight: 300;
        z-index: -1;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 16px;
        padding: 13px 80px 13px 20px;
        border: none;
        display: block;
        background: transparent;
        width: 100%;
        padding-right: 40px;
        color: transparent;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5;
        cursor: pointer;

        &:focus {
            outline-offset: 0px;
        }

        &.js-selected {
            color: $input;

        }

        &.js-selected+label {
            bottom: 105%;
            left: 15px;
            font-size: 10px !important;
            animation: selectAnimation 0.3s ease-out;
        }
    }

    option {
        border: none;
        padding: 10px;
        font-size: 16px;
        color: $input;
    }

    &:after {
        content: url("../img/arrow-select.svg");
        position: absolute;
        right: 5px;
        top: 15px;
        z-index: -1;
        background: #fff;
        width: 30px;
        height: 30px;
        text-align: center;
    }

    @media(max-width:$grid-breakpoints-sm) {
        width: 100%;

        select {
            width: 100%;
        }
    }

    &.form__select--white {
        background: #fff;
        border: none;
        width: 100%;
        margin-top: 0;
        margin-bottom: 25px;

        label {
            font-size: 18px;
        }

        select {
            font-size: 18px;
        }

        option {
            font-size: 18px;
        }
    }

    &.is--invalid {
        &:after {
            content: url("../img/arrow-select-red.svg");
        }
    }
}



.form__checkbox {
    margin-bottom: 15px;

    input {
        position: absolute;
        left: -5000px;
        opacity: 0;
    }

    a {
        text-decoration: underline;
    }

    label {
        position: relative;
        padding-left: 40px;
        line-height: 20px;
        color: $txt;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;
        display: inline-block;

        &:before {
            content: "";
            width: 30px;
            height: 30px;
            position: absolute;
            top: 1px;
            left: 0;
            display: block;
            border: 1px solid #fff;
            background-color: #fff;
            transition: all 0.3s ease-out;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 7px;
            left: 8px;
            width: 0;
            height: 0px;


            transform: rotate(-45deg);
        }
    }

    input:checked~label:before {
        background-color: $main;

    }




    input:checked~label:after {

        width: 15px;
        height: 11px;
        border-bottom: 3px solid #fff;
        border-left: 3px solid #fff;
    }

    input:focus-visible~label:before {
        outline: 2px solid #00af9a;
        outline-offset: 2px;
    }

    input[type="radio"] {
        &:before {
            border-radius: 50%;
        }
    }

    input[type="radio"]:checked~label:after {
        width: 10px;
        height: 10px;
        background: $main;

    }

    &.is--invalid {

        label:before {
            border-color: $red;
        }
    }
}

.form__checkbox--big {
    label {
        font-size: 16px;
        line-height: 30px;
    }
}

.form__input {
    margin-bottom: 25px;
    position: relative;

    input,
    textarea {
        width: 100%;
        border: none;
        padding: 12px 15px;
        color: $txt;
        font-size: 18px;
        border: none;
        font-weight: 300;
        line-height: 1.5;
    }

    label {
        top: 15px;

        font-size: 16px;
        z-index: 2;
        padding: 3px 30px 3px 0;
        width: auto;
        display: inline-block;
        overflow: hidden;
        margin: 0;
        left: 0;
        line-height: 16px;
        border-radius: 10px;
        position: absolute;
        transition: top 0.3s ease-in-out;
        color: $placeholder;
        white-space: nowrap;
        opacity: 0;
        z-index: -1;
    }

    input:focus+label,
    .focus,
    textarea:focus+label,
    &.noempty label {
        top: -18px;
        font-size: 10px !important;
        z-index: 2;
        padding: 3px 30px 3px 0;
        width: auto;
        display: inline-block;
        overflow: hidden;
        margin: 0;
        left: 15px;
        line-height: 10px;
        border-radius: 10px;
        opacity: 1;
        z-index: initial;
        color: $txt-light;

    }

    input:focus::placeholder {
        color: transparent;
    }

    &.is--invalid {
        color: $red;

        &:after {
            position: absolute;
            right: 15px;
            top: 2px;
            content: "+";
            font-size: 30px;
            transform: rotate(45deg);
        }
    }

    &.is--valid {}

}



.form__input--error {
    color: $red;
    font-size: 10px;
    display: block;
    margin-top: 5px;

}


.acc__content {
    display: none;
}

.form__select+.form__input--error {
    position: relative;
    top: -20px;
}


.fieldset {
    border: 1px solid #000;
    padding: 30px 30px 5px 30px;
    margin-bottom: 30px;

    legend {
        font-weight: 700;
        font-size: 20px;
    }

}

.fieldset__row {
    gap: 0 30px;
    display: flex;
    flex-wrap: wrap;

    .form__input {
        width: 100%;
    }

    @media(min-width: $grid-breakpoints-md) {
        .form__input {
            flex: 0 0 50%;
            max-width: calc(50% - 15px);
        }
    }

}

.form__file {
  position:relative;

    input {
        border: none;
        background: #fff;
        height:50px;
        margin-bottom:25px;
    }

    label {
        position:absolute;
        top:-25px;
        left:0;
    }
}


.fieldset__list {
    li {
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        border-bottom:1px solid $line;

    }


    @media(min-width: $grid-breakpoints-md) {
        .form__input {
            flex: 0 0 30%;
            max-width: 30%;
        }
    }
}