@font-face {
	font-family: 'Poppins';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/poppins-v20-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/poppins-v20-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/poppins-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/poppins-v20-latin-ext_latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-600 - latin-ext_latin */
  @font-face {
	font-family: 'Poppins';
	font-display: swap;
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/poppins-v20-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/poppins-v20-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/poppins-v20-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/poppins-v20-latin-ext_latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700 - latin-ext_latin */
  @font-face {
	font-family: 'Poppins';
	font-display: swap;
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/poppins-v20-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/poppins-v20-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/poppins-v20-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/poppins-v20-latin-ext_latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-800 - latin-ext_latin */
  @font-face {
	font-family: 'Poppins';
	font-display: swap;
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/poppins-v20-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/poppins-v20-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/poppins-v20-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/poppins-v20-latin-ext_latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-900 - latin-ext_latin */
  @font-face {
	font-family: 'Poppins';
	font-display: swap;
	font-style: normal;
	font-weight: 900;
	src: url('../fonts/poppins-v20-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/poppins-v20-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/poppins-v20-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/poppins-v20-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/poppins-v20-latin-ext_latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-300 - latin-ext_latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-display: swap;
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/source-sans-pro-v21-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-regular - latin-ext_latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src: url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-600 - latin-ext_latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-display: swap;
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/source-sans-pro-v21-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-700 - latin-ext_latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-display: swap;
	font-weight: 700;
	src: url('../fonts/source-sans-pro-v21-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-900 - latin-ext_latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-display: swap;
	font-style: normal;
	font-weight: 900;
	src: url('../fonts/source-sans-pro-v21-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/source-sans-pro-v21-latin-ext_latin-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }